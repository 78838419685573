import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActionType, IPromotion, PointsRoundingCode, RevenueRoundingCode } from "shared/lib/common/interfaces";
import { IQrScannerPageParams } from "shared/lib/common/pages";
import { TranslateService } from "shared/lib/common/services";
import { IButton } from "shared/lib/common/components";

@Component({
  selector: "shared-revenue-module",
  templateUrl: "./revenue-module.component.html",
  styleUrls: ["./revenue-module.component.scss"],
})
export class RevenueModuleComponent implements OnInit {
  @Input() public promotions: IPromotion[];
  @Input() public revenueRounding: RevenueRoundingCode;
  @Input() public pointsRounding: PointsRoundingCode;
  @Input() public revenueMode: "INT" | "FLOAT";
  @Input() public mode: "extended" | "simple" | "manual";
  @Output() public backEvent: EventEmitter<IQrScannerPageParams | string | number> = new EventEmitter();

  public negativeMode: boolean;
  public revenue: number;
  public totalPoints: number;
  public modeButtons: Array<string>;

  constructor(public translate: TranslateService) {}

  public ngOnInit(): void {
    this.revenue = 0;
    this.totalPoints = 0;
    this.negativeMode = false;
    switch (this.mode) {
      case "extended":
        this.modeButtons = ["plusMinus", "c", "ac", "7", "8", "9", "4", "5", "6", "1", "2", "3", "delete", "0", "00"];
        break;
      case "simple":
        this.modeButtons = ["plusMinus", "c", "delete", "7", "8", "9", "4", "5", "6", "1", "2", "3", "00", "0", "enter"];
        break;
      case "manual":
        this.modeButtons = ["7", "8", "9", "4", "5", "6", "1", "2", "3", "delete", "0", "enter"];
        break;
    }
  }

  public press(value: IButton): void {
    switch (value) {
      case "plusMinus":
        this.toggleNegativeMode();
        break;
      case "c":
        this.reset();
        break;
      case "ac":
        this.resetAll();
        break;
      case "enter":
        this.enter();
        break;
      case "delete":
        this.remove();
        break;
      default:
        this.add(value);
        break;
    }
  }

  public toggleNegativeMode(): void {
    this.negativeMode = !this.negativeMode;
    this.resetAll();
  }

  public getArray(n: number): any[] {
    return Array(n);
  }

  public resetAll(): void {
    this.revenue = 0;
    this.totalPoints = 0;
    this.promotions = (this.promotions || []).map(p => {
      if (p) return { ...p, revenue: 0, points: 0 };
    });
  }

  public reset(): void {
    this.revenue = 0;
  }

  public remove(): void {
    if (this.revenue > 0) {
      let revenue: string;
      switch (this.revenueMode) {
        case "INT":
          revenue = this.revenue.toFixed(0);
          revenue = revenue.substring(0, revenue.length - 1) || "0";
          this.revenue = parseInt(revenue, 10);
          break;
        case "FLOAT":
          revenue = (this.revenue * 100).toFixed(0);
          revenue = revenue.substring(0, revenue.length - 1) || "0";
          this.revenue = parseInt(revenue, 10) / 100;
          break;
      }
    }
  }

  public add(number: string): void {
    let revenue: string;
    switch (this.revenueMode) {
      case "INT":
        revenue = this.revenue.toFixed(0);
        revenue += number;
        this.revenue = parseInt(revenue, 10);
        break;
      case "FLOAT":
        revenue = (this.revenue * 100).toFixed(0);
        revenue += number;
        this.revenue = parseInt(revenue, 10) / 100;
        break;
    }
  }

  public applyFactor({ type, revenue, factor }: IPromotion): void {
    let newRevenue = this.revenue;
    if (this.negativeMode || type === "RMB") newRevenue = Math.abs(newRevenue) * -1;
    this.promotions = this.promotions.map(actualPromotion => {
      if (actualPromotion && actualPromotion.type === type) {
        actualPromotion.revenue += newRevenue;
        actualPromotion.points = this.roundPoints(this.roundRevenue(actualPromotion.revenue) * factor);
      }
      return actualPromotion;
    });
    this.totalPoints = this.promotions.reduce((acumulate, promotion) => (promotion ? promotion.points + acumulate : 0 + acumulate), 0);
    this.reset();
  }

  public assignPoints(): void {
    const params = {};
    this.promotions.forEach(p => {
      if (p && p.revenue) params[p.type] = p;
    });
    const queryParams: IQrScannerPageParams = {
      type: "earn",
      manualPoints: this.totalPoints,
      revenueRmb: params[ActionType.RMB] ? params[ActionType.RMB].revenue * -1 : 0,
      revenueRmt: params[ActionType.RMT] ? params[ActionType.RMT].revenue : 0,
      revenueRmc1: params[ActionType.RMC1] ? params[ActionType.RMC1].revenue : 0,
      revenueRmc2: params[ActionType.RMC2] ? params[ActionType.RMC2].revenue : 0,
      revenueRmc3: params[ActionType.RMC3] ? params[ActionType.RMC3].revenue : 0,
    };
    this.backEvent.emit(queryParams);
    this.resetAll();
  }

  public enter(): void {
    if (this.mode === "manual") {
      this.backEvent.emit(this.roundPoints(this.revenue));
    } else {
      let newRevenue = this.revenue;
      if (this.negativeMode) newRevenue = Math.abs(newRevenue) * -1;
      const queryParams: IQrScannerPageParams = {
        type: "earn",
        totalPoints: this.roundPoints(newRevenue),
        totalRevenue: this.roundRevenue(newRevenue),
      };
      this.backEvent.emit(queryParams);
    }
    this.reset();
  }

  private roundRevenue(revenue: number): number {
    const absRevenue = Math.abs(revenue);
    const algebraicSign = revenue < 0 ? -1 : 1;
    switch (this.revenueRounding) {
      case "UP":
        return Math.ceil(absRevenue) * algebraicSign;
      case "DOWN":
        return Math.floor(absRevenue) * algebraicSign;
      case "NO":
        return revenue;
      default:
        return Math.round(absRevenue) * algebraicSign;
    }
  }

  private roundPoints(points: number): number {
    const absPoints = Math.abs(points);
    const algebraicSign = points < 0 ? -1 : 1;
    switch (this.pointsRounding) {
      case "UP":
        return Math.ceil(absPoints) * algebraicSign;
      case "DOWN":
        return Math.floor(absPoints) * algebraicSign;
      default:
        return Math.round(absPoints) * algebraicSign;
    }
  }
}
