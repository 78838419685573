import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "shared-scan-frame",
  templateUrl: "./scan-frame.component.html",
  styleUrls: ["./scan-frame.component.scss"],
})
export class ScanFrameComponent implements OnInit {
  @Input() public loading: boolean;
  constructor() {}

  public ngOnInit(): void {}
}
