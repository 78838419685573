/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./summary.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../v1/components/loading/loading.component.ngfactory";
import * as i3 from "../../../v1/components/loading/loading.component";
import * as i4 from "../../components/summary/summary.component.ngfactory";
import * as i5 from "../../components/summary/summary.component";
import * as i6 from "../../../common/services/translate/translate.service";
import * as i7 from "@angular/common";
import * as i8 from "./summary.page";
import * as i9 from "../../services/device-mounting/device-mounting.service";
import * as i10 from "../../services/account/account.service";
import * as i11 from "@ionic/angular";
import * as i12 from "../../../common/services/user/user.service";
import * as i13 from "../../../common/services/config/config.service";
import * as i14 from "../../../common/services/toast/toast.service";
var styles_SummaryPage = [i0.styles];
var RenderType_SummaryPage = i1.ɵcrt({ encapsulation: 0, styles: styles_SummaryPage, data: {} });
export { RenderType_SummaryPage as RenderType_SummaryPage };
function View_SummaryPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-loading", [], null, null, null, i2.View_LoadingComponent_0, i2.RenderType_LoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_SummaryPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-v2-summary", [["class", "summary"]], null, [[null, "clickEvent"], [null, "checkOutEvent"], [null, "removeItemEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickEvent" === en)) {
        var pd_0 = (_co.modal.dismiss(_co.revenues) !== false);
        ad = (pd_0 && ad);
    } if (("checkOutEvent" === en)) {
        var pd_1 = (_co.showAlert() !== false);
        ad = (pd_1 && ad);
    } if (("removeItemEvent" === en)) {
        var pd_2 = (_co.updatePreview() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_SummaryComponent_0, i4.RenderType_SummaryComponent)), i1.ɵdid(1, 49152, null, 0, i5.SummaryComponent, [i6.TranslateService], { revenue: [0, "revenue"], points: [1, "points"], revenues: [2, "revenues"], buttonDirection: [3, "buttonDirection"], decimalRevenue: [4, "decimalRevenue"] }, { clickEvent: "clickEvent", checkOutEvent: "checkOutEvent", removeItemEvent: "removeItemEvent" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SummaryPage_1)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.amount; var currVal_1 = _co.points; var currVal_2 = _co.revenues; var currVal_3 = "DOWN"; var currVal_4 = ((_co.deviceMounting == null) ? null : ((_co.deviceMounting.profile == null) ? null : _co.deviceMounting.profile.decimalRevenue)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.loading; _ck(_v, 3, 0, currVal_5); }, null); }
export function View_SummaryPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-v2-summary-page", [], null, null, null, View_SummaryPage_0, RenderType_SummaryPage)), i1.ɵdid(1, 49152, null, 0, i8.SummaryPage, [i9.DeviceMountingService, i10.AccountService, i11.ModalController, i6.TranslateService, i11.AlertController, i12.UserService, i13.ConfigService, i14.ToastService], null, null)], null, null); }
var SummaryPageNgFactory = i1.ɵccf("shared-v2-summary-page", i8.SummaryPage, View_SummaryPage_Host_0, { revenues: "revenues", points: "points", amount: "amount", mode: "mode" }, {}, []);
export { SummaryPageNgFactory as SummaryPageNgFactory };
