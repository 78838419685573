import { Component, OnInit } from "@angular/core";

@Component({
  selector: "shared-tap",
  templateUrl: "./tap.component.html",
  styleUrls: ["./tap.component.scss"],
})
export class TapComponent implements OnInit {
  constructor() {}

  public ngOnInit(): void {}
}
