import { Injectable } from "@angular/core";
import * as crypto from "crypto-js";
import { ICoupon, IVoucher } from "shared/lib/common/interfaces";

const ONE_DAY_MILI_SECONDS = 1000 * 60 * 60 * 24;
const ENCRYPT_KEY = "76d44500-e9be-48d4-86be-9236308fc57c";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  constructor() {}

  public waiting(time: number = 2000): Promise<any> {
    return new Promise(resolve => {
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        resolve("");
      }, time);
    });
  }

  public encrypt(text: string): string {
    return crypto.AES.encrypt(JSON.stringify(text), ENCRYPT_KEY).toString();
  }

  public decrypt(text: string): string | any | null {
    if (text && text !== "") {
      try {
        return JSON.parse(crypto.AES.decrypt(text, ENCRYPT_KEY).toString(crypto.enc.Utf8));
      } catch (e) {
        return crypto.AES.decrypt(text, ENCRYPT_KEY).toString(crypto.enc.Utf8);
      }
    } else {
      return null;
    }
  }

  public generateRandom(): string {
    return crypto.MD5(Date.now().toString()).toString();
  }

  public parseDate(date: string): number {
    return date ? Math.round(new Date(date).getTime()) : null;
  }

  public getNumberOfDays(coupon: ICoupon): number {
    const miliSecondsToUnlock = coupon.validFromDate.unixTimeStamp - new Date().getTime();
    return Math.ceil(miliSecondsToUnlock / ONE_DAY_MILI_SECONDS);
  }

  public lockedDueToDates(ticket: IVoucher | ICoupon): boolean {
    return ticket.validFromDate
      ? this.isAfterNow(ticket.validFromDate.unixTimeStamp)
      : false || ticket.validToDate
      ? this.isBeforeNow(ticket.validToDate.unixTimeStamp)
      : false;
  }

  public isBeforeNow(date: number): boolean {
    return new Date().getTime() >= date;
  }

  public isAfterNow(date: number): boolean {
    return new Date().getTime() <= date;
  }

  public isBetweenDates(start: number, end: number): boolean {
    const now = new Date().getTime();
    return now >= start && now <= end;
  }

  public decodeHTMLEntity(str: string): string {
    const element = document.createElement("p");
    element.innerHTML = str;
    return element.textContent;
  }

  public getReceiptFormatDate(date: Date): string {
    const [day, month, year] = date.toLocaleDateString("DE").split(".");
    const [hour, minutes] = date.toLocaleTimeString("DE").split(":");
    return `${year}${month.padStart(2, "0")}${day.padStart(2, "0")}-${hour.padStart(2, "0")}${minutes.padStart(2, "0")}`;
  }
}
