import { formatDate } from "@angular/common";
import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import * as Sentry from "@sentry/browser";
import { LOCAL_STORAGE } from "shared/lib/common/enums";
import { IBarcodeType, IVoucher } from "shared/lib/common/interfaces";
import { Align, EPOSDevice, IPrinter, Line } from "shared/lib/common/interfaces/epson.interface";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { TranslateService } from "shared/lib/common/services/translate/translate.service";
import { UserService } from "shared/lib/common/services/user/user.service";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";
import { EVENT_LISTENERS } from "shared/lib/common/services/third-party-scripts/third-party-scripts.service";
import { IResponse } from "shared/lib/common/interfaces";

@Injectable({
  providedIn: "root",
})
export class PrintService {
  private epsonPOSDevice: EPOSDevice;
  private printer: IPrinter;

  constructor(
    private config: ConfigService,
    private utils: UtilsService,
    private user: UserService,
    private translate: TranslateService,
    private platform: Platform,
  ) {
    if ((<any>window).epson) {
      this.epsonPOSDevice = new (<any>window).epson.ePOSDevice();
    } else {
      document.addEventListener(EVENT_LISTENERS.ON_LOAD_EPSON_SCRIPT, function(event: any): void {
        this.epsonPOSDevice = new (<any>window).epson.ePOSDevice();
      });
    }
  }

  public setIp(ip: string): void {
    localStorage.setItem(LOCAL_STORAGE.IP, this.utils.encrypt(ip));
  }

  public getIp(): string {
    return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.IP));
  }

  public removeIp(): void {
    localStorage.removeItem(LOCAL_STORAGE.IP);
  }

  public async printTicket(voucher: IVoucher): Promise<IResponse<void, { message: "NOT_CONNECTED" | "NO_PAPER" | "COUPON_NOT_FOUND" }>> {
    const connected = await this.connect();
    if (connected.ok === true) {
      const created = await this.createDevice();
      if (created.ok === true) {
        const externalCodeResponse = this.user.getExternalCode(voucher);
        if (externalCodeResponse.ok === true) {
          this.printer = created.response;
          this.printer.addTextLang("de");
          this.print(this.config.getMountingConfig().storeName, Align.center, Line.title);
          this.addBlankLine();
          this.platform.is("capacitor")
            ? this.print(this.config.getMountingConfig().clientName, Align.center, Line.title)
            : await this.addLogo();
          this.addBlankLine();
          this.print("Coupon", Align.left, Line.paragraph);
          this.print(voucher.description, Align.left, Line.subtitle);
          this.addBlankLine();
          this.print(this.translate._("VALID_AT"), Align.left, Line.paragraph);
          this.print(this.config.getMountingConfig().clientName, Align.left, Line.paragraph);
          this.print(this.config.getMountingConfig().storeName, Align.left, Line.paragraph);
          this.addBlankLine();
          if (externalCodeResponse.response.code) {
            if (["CUSTOM", "P4M"].includes(externalCodeResponse.response.type)) {
              this.print(externalCodeResponse.response.code, Align.center, Line.paragraph);
            } else {
              this.printCode(externalCodeResponse.response.type, externalCodeResponse.response.code);
              this.addBlankLine();
            }
          } else {
            this.print(voucher.title, Align.center, Line.paragraph);
          }
          this.addBlankLine();
          this.print(this.translate._("TICKET_PRINT1"), Align.center, Line.paragraph);
          this.addBlankLine();
          this.print(this.translate._("TICKET_PRINT2"), Align.center, Line.paragraph);
          this.print(this.translate._("TICKET_PRINT3"), Align.center, Line.paragraph);
          this.print(this.translate._("TICKET_PRINT4"), Align.center, Line.paragraph);
          this.print(this.translate._("TICKET_PRINT5"), Align.center, Line.paragraph);
          this.addBlankLine();
          this.print(this.translate._("ISSUED_ON"), Align.center, Line.paragraph);
          this.print(formatDate(new Date(), "dd.MM.yyyy hh:mm", this.translate.getSessionLanguage()), Align.center, Line.paragraph);
          this.print(this.translate._("CUSTOMER_CARD") + ": " + (this.user.getUser() || {}).keyCode, Align.center, Line.paragraph);
          this.addBlankLine();
          this.print(
            this.translate._("REDEEMABLE_UNTIL") + formatDate(new Date(), "dd.MM.yyyy", this.translate.getSessionLanguage()),
            Align.center,
            Line.paragraph,
          );
          this.addBlankLine();
          this.printer.addCut();
          this.printer.send();
          this.epsonPOSDevice.deleteDevice(this.printer, () => this.epsonPOSDevice.disconnect());
          return { ok: true, response: null };
        } else {
          this.epsonPOSDevice.disconnect();
          return {
            ok: false,
            error: { message: externalCodeResponse.error.message },
          };
        }
      } else {
        this.epsonPOSDevice.disconnect();
        return {
          ok: false,
          error: { message: created.error.message },
        };
      }
    } else {
      this.epsonPOSDevice.disconnect();
      return {
        ok: false,
        error: { message: connected.error.message },
      };
    }
  }

  public async testConnection(ip: string): Promise<IResponse<void, { message: "NOT_CONNECTED" }>> {
    const connected = await this.connect(ip);
    if (connected.ok === true) {
      return { ok: true, response: null };
    } else {
      return { ok: false, error: { message: connected.error.message } };
    }
  }

  private addLogo(): Promise<void> {
    return new Promise(resolve => {
      const c = document.createElement("canvas");
      c.id = "canvas";
      document.body.appendChild(c);
      const canvas = document.getElementsByTagName("canvas")[0];
      const img = new Image();
      img.src = this.config.getPrinterLogo();
      img.crossOrigin = "Anonymous";
      img.onload = () => {
        c.width = img.width;
        c.height = img.height;
        canvas.getContext("2d").drawImage(img, 0, 0);
        this.printer.brightness = 1.5;
        this.printer.halftone = 0;
        this.printer.addImage(canvas.getContext("2d"), 0, 0, c.width, c.height, "color_1", "gray16");
        resolve();
      };
      img.onerror = () => {
        resolve();
      };
    });
  }

  private print(text: string, align: Align, type: Line): void {
    const isBold = type === Line.title || type === Line.subtitle;
    this.printer.addTextAlign(align);
    this.printer.addTextStyle(false, false, isBold);
    this.printer.addTextSize(type === Line.title ? 2 : 1, type === Line.title ? 2 : 1);
    this.printer.addText(`${this.utils.decodeHTMLEntity(text)}\n`);
  }

  private printCode(type: IBarcodeType, code: string): void {
    this.printer.addTextAlign(Align.center);
    let externalCode: string = code;
    switch (type) {
      case "QRCODE":
        this.printer.addSymbol(code, "qrcode_micro", "level_0", 10);
        break;
      case "CODE128":
        this.printer.addBarcode(code, "code128", "none", "font_a", 3, 162);
        if (code.match("{A") || code.match("{B") || code.match("{C")) {
          externalCode = code.substr(2);
        }
        break;
      case "EAN13":
        this.printer.addBarcode(code, "ean13", "none", "font_a", 3, 162);
        break;
      case "EAN8":
        this.printer.addBarcode(code, "ean8", "none", "font_a", 3, 162);
        break;
      default:
        break;
    }
    this.printer.addText("\n");
    this.print(externalCode, Align.center, Line.paragraph);
  }

  private addBlankLine(): void {
    this.printer.addText("\n\n");
  }

  private connect(ip?: string): Promise<IResponse<void, { message: "NOT_CONNECTED" }>> {
    const port = this.platform.is("capacitor") ? 8008 : 8043;
    return new Promise(resolve => {
      let auxIp: string;
      if (!ip || ip === "") {
        const savedIp = this.getIp();
        if (!savedIp || savedIp === "") {
          resolve({ ok: false, error: { message: "NOT_CONNECTED" } });
        } else {
          auxIp = savedIp;
        }
      } else {
        auxIp = ip;
      }
      this.epsonPOSDevice.connect(auxIp, port, result => {
        if (result === "OK" || result === "SSL_CONNECT_OK") {
          resolve({ ok: true, response: null });
        }
        Sentry.captureMessage(result, Sentry.Severity.Debug);
        resolve({
          ok: false,
          error: { message: "NOT_CONNECTED" },
        });
      });
    });
  }

  private createDevice(): Promise<IResponse<IPrinter, { message: "NO_PAPER" }>> {
    return new Promise(resolve => {
      this.epsonPOSDevice.createDevice(
        "local_printer",
        "type_printer",
        { buffer: false, crypto: !this.platform.is("capacitor") },
        (device, result) => {
          if (result && result !== "OK") {
            Sentry.captureMessage(result, Sentry.Severity.Debug);
            resolve({ ok: false, error: { message: "NO_PAPER" } });
          } else {
            resolve({ ok: true, response: device });
          }
        },
      );
    });
  }
}
