import { Injectable } from "@angular/core";
import { LOCAL_STORAGE } from "shared/lib/common/enums";
import { I18n, ILanguage, LANGUAGE } from "shared/lib/common/i18n/translate";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";

@Injectable({
  providedIn: "root",
})
export class TranslateService {
  private sessionLanguage: ILanguage;
  private translations: { en: typeof LANGUAGE.en; de: typeof LANGUAGE.de };

  constructor(private utils: UtilsService) {
    this.translations = LANGUAGE;
  }

  public setLanguage(language: ILanguage): void {
    localStorage.setItem(LOCAL_STORAGE.LANGUAGE, this.utils.encrypt(language));
  }

  public getLanguage(): ILanguage {
    return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.LANGUAGE)) as ILanguage;
  }

  public setSessionLanguage(sessionLanguage: ILanguage = this.getLanguage()): void {
    this.sessionLanguage = sessionLanguage;
  }

  public getSessionLanguage(): ILanguage {
    return this.sessionLanguage || this.getLanguage() || "en";
  }

  public async setTranslations(remoteTraslations: { en: typeof LANGUAGE.en; de: typeof LANGUAGE.de } = { en: {}, de: {} }): Promise<void> {
    if (remoteTraslations) {
      this.translations = {
        en: { ...LANGUAGE.en, ...remoteTraslations.en },
        de: { ...LANGUAGE.de, ...remoteTraslations.de },
      };
    } else {
      this.translations = {
        en: LANGUAGE.en,
        de: LANGUAGE.de,
      };
    }
  }

  public _(key: I18n, ...args: string[]): string {
    let translate = this.translations[this.getSessionLanguage()][key] || key;
    if (args && args.length > 0 && translate !== "") {
      args.forEach((argument, index) => {
        translate = translate.replace(`$${index}`, argument);
      });
    }
    return translate;
  }
}
