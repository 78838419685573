/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MembershipEventSubject } from './membership-event-subject';


export interface MembershipEvent { 
    /**
     * account identifier
     */
    accountId?: string;
    createdAt?: string;
    id?: string;
    interactionId?: string;
    membershipEventType?: MembershipEvent.MembershipEventTypeEnum;
    /**
     * unique identifier
     */
    membershipId?: string;
    program?: string;
    subject?: MembershipEventSubject;
    /**
     * user identifier
     */
    userId?: string;
    userName?: string;
}
export namespace MembershipEvent {
    export type MembershipEventTypeEnum = 'MEMBERSHIP_CREATED' | 'MEMBERSHIP_TERMINATED' | 'MEMBERSHIP_OPTINS_CHANGED';
    export const MembershipEventTypeEnum = {
        CREATED: 'MEMBERSHIP_CREATED' as MembershipEventTypeEnum,
        TERMINATED: 'MEMBERSHIP_TERMINATED' as MembershipEventTypeEnum,
        OPTINSCHANGED: 'MEMBERSHIP_OPTINS_CHANGED' as MembershipEventTypeEnum
    };
}


