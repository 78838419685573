import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TranslateService } from "shared/lib/common/services";

@Component({
  selector: "shared-connection-banner",
  templateUrl: "./connection-banner.component.html",
  styleUrls: ["./connection-banner.component.scss"],
})
export class ConnectionBannerComponent {
  @Input() public connection: boolean;
  @Input() public socketConnection: boolean;
  @Input() public isDeviceMounted: boolean;
  @Input() public loading?: boolean;
  @Output() public reconnectSocket: EventEmitter<any> = new EventEmitter();

  constructor(public translate: TranslateService) {}

  public reconnect(): void {
    this.reconnectSocket.emit();
  }
}
