/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProgramUnit } from './program-unit';


export interface IdentifierData { 
    /**
     * code that can be used to identify an account
     */
    code?: string;
    /**
     * display value of the identifier e.g. for printing
     */
    displayCode?: string;
    issuer?: ProgramUnit;
    type: IdentifierData.TypeEnum;
}
export namespace IdentifierData {
    export type TypeEnum = 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE';
    export const TypeEnum = {
        APPCODE: 'APPCODE' as TypeEnum,
        CARDCODE: 'CARDCODE' as TypeEnum,
        EXTERNALCODE: 'EXTERNALCODE' as TypeEnum
    };
}


