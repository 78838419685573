import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Revenue } from "shared/lib/v2/pages";
import { TranslateService } from "shared/lib/common/services";

@Component({
  selector: "shared-v2-summary",
  templateUrl: "./summary.component.html",
  styleUrls: ["./summary.component.scss"],
})
export class SummaryComponent {
  @Input() public revenue: number;
  @Input() public points: number;
  @Input() public revenues: Revenue[];
  @Input() public buttonDirection: "UP" | "DOWN";
  @Input() public decimalRevenue: boolean;
  @Output() public clickEvent: EventEmitter<any> = new EventEmitter();
  @Output() public checkOutEvent: EventEmitter<any> = new EventEmitter();
  @Output() public removeItemEvent: EventEmitter<any> = new EventEmitter();

  constructor(public t: TranslateService) {}

  public removeItem(itemId: number): void {
    this.revenues.splice(itemId, 1);
    this.removeItemEvent.emit(itemId);
  }
}
