import { Component, OnInit, Input } from "@angular/core";
import { SpinnerTypes } from "@ionic/core";

@Component({
  selector: "shared-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"],
})
export class LoadingComponent implements OnInit {
  @Input() public name: SpinnerTypes = "crescent";
  constructor() {}

  public ngOnInit(): void {}
}
