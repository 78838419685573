/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Store } from './store';
import { Partner } from './partner';


export interface Booking { 
    bookingId?: string;
    /**
     * time when the booking is created.
     */
    bookingTime?: string;
    bookingType?: Booking.BookingTypeEnum;
    bookingTypeCode?: string;
    /**
     * coupon id referenced with this booking.
     */
    couponId?: string;
    interactionId?: string;
    /**
     * is true if valuetime is in future, otherwise false
     */
    locked?: boolean;
    name?: string;
    partner?: Partner;
    points?: number;
    reason?: string;
    /**
     * technical id of the domainObject that is referenced with this booking. (e.g. couponId)
     */
    referenceId?: string;
    store?: Store;
    /**
     * transaction id referenced with this booking.
     */
    transactionId?: string;
    /**
     * value date of the booking. Is in future for locked bookings.
     */
    valueTime?: string;
}
export namespace Booking {
    export type BookingTypeEnum = 'BURNBOOKING' | 'EARNBOOKING';
    export const BookingTypeEnum = {
        BURNBOOKING: 'BURNBOOKING' as BookingTypeEnum,
        EARNBOOKING: 'EARNBOOKING' as BookingTypeEnum
    };
}


