import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { SharedRoutes } from "shared/lib/common/enums";
import { RoutingService, TranslateService, UserService } from "shared/lib/common/services";

@Injectable({
  providedIn: "root",
})
export class IsUserScannedGuard implements CanActivate {
  constructor(public user: UserService, public translate: TranslateService, public routing: RoutingService) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.user.getUser()) {
      return true;
    } else {
      this.routing.goForward([SharedRoutes.screenSaver]);
      return false;
    }
  }
}
