/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountCouponI18nFields } from './account-coupon-i18n-fields';
import { CustomProperty } from './custom-property';
import { Image } from './image';
import { ProgramUnit } from './program-unit';
import { ExternalCode } from './external-code';


export interface AccountCoupon { 
    /**
     * unique identifier
     */
    accountCouponId?: string;
    /**
     * account identifier
     */
    accountId?: string;
    /**
     * coupon identifier
     */
    couponId?: string;
    /**
     * Array of custom properties
     */
    customProperties?: Array<CustomProperty>;
    externalCode?: ExternalCode;
    externalReference?: string;
    i18nFields?: { [key: string]: AccountCouponI18nFields; };
    /**
     * Array of images including tags and urls
     */
    images?: Array<Image>;
    /**
     * maximal redeem count per account
     */
    maxRedeemCount?: number;
    /**
     * maximal redeem count per coupon
     */
    maxRedeemCountGlobal?: number;
    /**
     * neccessary point amount for redemption
     */
    points?: number;
    /**
     * Array of businessUnits where the coupon is redeemable
     */
    pointsOfRedemption?: Array<ProgramUnit>;
    /**
     * current redeem count per accountcoupon
     */
    redeemCount?: number;
    /**
     * current redeem count per coupon
     */
    redeemCountGlobal?: number;
    /**
     * name of the statelevel, necessary for redemption
     */
    stateLevel?: string;
    usageType?: AccountCoupon.UsageTypeEnum;
    /**
     * ...to this date (e.g. 2018-02-05 09:10:16)
     */
    validFrom?: string;
    /**
     * ...to this date (e.g. 2018-02-05 09:10:16)
     */
    validTo?: string;
}
export namespace AccountCoupon {
    export type UsageTypeEnum = 'INCENTIVE' | 'REWARD';
    export const UsageTypeEnum = {
        INCENTIVE: 'INCENTIVE' as UsageTypeEnum,
        REWARD: 'REWARD' as UsageTypeEnum
    };
}


