<div class="keypad">
  <ng-container *ngFor="let button of buttons">
    <ion-button *ngIf="button === 'plusMinus'" class="keypad__button --with-icons" (tap)="onPress(button)">
      <i-plus></i-plus>
      <i-minus></i-minus>
    </ion-button>
    <ion-button *ngIf="button === 'c'" class="keypad__button --grey" (tap)="onPress(button)">
      C
    </ion-button>
    <ion-button *ngIf="button === 'ac'" class="keypad__button --yellow" (tap)="onPress(button)">
      AC
    </ion-button>
    <ion-button *ngIf="isNumber(button)" class="keypad__button" (tap)="onPress(button)">
      {{ button }}
    </ion-button>
    <ion-button *ngIf="button === 'delete'" class="keypad__button" color="danger" (tap)="onPress(button)">
      <i-delete></i-delete>
    </ion-button>
    <ion-button *ngIf="button === 'enter'" class="keypad__button --green" (tap)="onPress(button)" [disabled]="disabled">
      <i-corner-down-right></i-corner-down-right>
    </ion-button>
  </ng-container>
</div>
