<div class="summary">
  <h1 class="summary__header" [innerHTML]="t._('SUMMARY')"></h1>
  <p *ngIf="!revenues?.length" class="summary__empty" [innerHTML]="t._('NO_COUPONS')"></p>
  <h1 *ngIf="revenues?.length" class="summary__list__header" [innerHTML]="t._('ITEMS')"></h1>
  <div *ngIf="revenues?.length" class="summary__list">
    <div class="summary__list__item" *ngFor="let revenue of revenues; let i = index">
      <div class="summary__list__item__info">
        <h3 class="summary__list__item__info__title" [innerHTML]="revenue?.name"></h3>
        <p class="summary__list__item__info__text"
          [innerHTML]="revenue?.money + ' € | ' + revenue?.points + ' ' + t._('POINTS_TO_EXCHANGE')"></p>
      </div>
      <ion-icon (click)="removeItem(i)" name="trash" class="summary__list__item__delete"></ion-icon>
    </div>
  </div>
  <shared-v2-summary-footer class="summary__footer" [decimalRevenue]="decimalRevenue" [revenue]="revenue"
    [points]="points" [buttonDirection]="buttonDirection" (clickEvent)="clickEvent.emit()"
    (checkOutEvent)="checkOutEvent.emit()">
  </shared-v2-summary-footer>
</div>