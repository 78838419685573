import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { IVoucher } from "shared/lib/common/interfaces";
import { UserService } from "shared/lib/common/services";

export interface ICouponCardInput {
  ticketSelected: IVoucher;
  showRegisterButton: boolean;
  registerMandatory: boolean;
}

export interface ICouponCardOutput {
  data?: IVoucher;
}

@Component({
  selector: "shared-v1-coupon-card-page",
  templateUrl: "./coupon-card.page.html",
  styleUrls: ["./coupon-card.page.scss"],
})
export class CouponCardPage {
  @Input() public ticketSelected: IVoucher;
  @Input() public showRegisterButton: boolean;
  @Input() public registerMandatory: boolean;

  constructor(private modal: ModalController, public user: UserService) {}

  public dismiss(coupon: IVoucher): void {
    this.modal.dismiss(coupon);
  }
}
