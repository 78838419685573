<svg
  version="1.1"
  class="scan-frame"
  [class.--show]="!loading"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 210 210"
  enable-background="new 0 0 210 210"
  xml:space="preserve"
>
  <g>
    <path class="scan-frame--fill" d="M10,47V10h37V0H7C3.14,0,0,3.14,0,7v40H10z" />
    <path class="scan-frame--fill" d="M10,163H0v40c0,3.859,3.14,7,7,7h40v-10H10V163z" />
    <path class="scan-frame--fill" d="M200,163v37h-37v10h40c3.859,0,7-3.141,7-7v-40H200z" />
    <path class="scan-frame--fill" d="M200,47h10V7c0-3.86-3.141-7-7-7h-40v10h37V47z" />
  </g>
</svg>
<ion-spinner [class.--show]="loading" class="scan-frame__loading"></ion-spinner>
