import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { AdminService, ToastService, TranslateService } from "shared/lib/common/services";
import { ConfigService } from "shared/lib/common/services";

export interface EnsureUnmountLoginModalOutputs {
  email: string;
  password: string;
}

@Component({
  selector: "shared-ensure-unmount-login-modal",
  templateUrl: "./ensure-unmount-login-modal.page.html",
  styleUrls: ["./ensure-unmount-login-modal.page.scss"],
})
export class EnsureUnmountLoginModal implements OnInit {
  public showPassword: boolean;
  public loading: boolean;
  public form: FormGroup;
  public releaseVersion: string;
  public isKeyboardActive: boolean;

  constructor(
    public translate: TranslateService,
    public fb: FormBuilder,
    public admin: AdminService,
    public config: ConfigService,
    public toast: ToastService,
    public modal: ModalController,
  ) {}

  public ngOnInit(): void {
    this.form = this.fb.group({
      email: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });
  }

  public ionViewWillEnter(): void {
    this.form.reset();
    this.loading = false;
    this.showPassword = false;
  }

  public changeShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  public updateKeyboardStatus(isActive: boolean): void {
    this.isKeyboardActive = isActive;
  }

  public back(): void {
    this.modal.dismiss();
  }

  public async login(email: string, password: string): Promise<void> {
    this.loading = true;
    const resp = await this.admin.adminLogin(email, this.config.getOrganization(), password);
    if (resp.ok === true) {
      this.modal.dismiss({ email, password });
    } else {
      this.toast.show(this.translate._(resp.error.message as any), "error");
    }
    this.loading = false;
  }

  public checkErrors(formControlName: string): boolean {
    const control = this.form.get(formControlName);
    return control.invalid && control.touched;
  }
}
