import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "shared-pin",
  templateUrl: "./pin.component.html",
  styleUrls: ["./pin.component.scss"],
})
export class PinComponent implements OnInit {
  public pin: string;
  @Input() public pinLength: number;
  @Output() public event: EventEmitter<string> = new EventEmitter();

  constructor() {}

  public ngOnInit(): void {
    this.pin = "";
  }

  public press(value: string): void {
    switch (value) {
      case "enter":
        this.enter();
        break;
      case "delete":
        this.delete();
        break;
      default:
        this.add(value);
        break;
    }
  }

  public add(key: string): void {
    this.pin = this.pin + key;
    if (this.pinLength && this.pinLength === this.pin.length) this.enter();
  }

  public delete(): void {
    this.pin = this.pin.slice(0, -1);
  }

  public enter(): void {
    this.event.emit(this.pin);
  }

  public isEnterDisabled(): boolean {
    return this.pin.length < this.pinLength;
  }

  public getArray(n: number): any[] {
    return Array(n);
  }
}
