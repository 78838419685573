import { Injectable } from "@angular/core";
import { HammerGestureConfig } from "@angular/platform-browser";

@Injectable()
export class MyHammerGestureConfig extends HammerGestureConfig {
  public overrides: { [key: string]: Object } = {
    tap: { threshold: 70, posTreshold: 10, time: 2500 },
    pan: { threshold: 1000 },
    swipe: { threshold: 1000 },
  };
}
