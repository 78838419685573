import { Component, OnInit } from "@angular/core";
import { SharedRoutes } from "shared/lib/common/enums";
import { IPromotion, PointsRoundingCode, RevenueRoundingCode } from "shared/lib/common/interfaces";
import { IQrScannerPageParams } from "shared/lib/common/pages";
import { AdminService, EnvironmentService, RoutingService, ToastService, TranslateService, UserService } from "shared/lib/common/services";
import { Platform } from "@ionic/angular";
import { ConfigService } from "shared/lib/common/services";

@Component({
  selector: "shared-v1-admin",
  templateUrl: "./admin.page.html",
  styleUrls: ["./admin.page.scss"],
})
export class AdminPage implements OnInit {
  public releaseVersion: string;
  public promotions: IPromotion[];
  public pointsRounding: PointsRoundingCode;
  public revenueRounding: RevenueRoundingCode;
  public revenueMode: "INT" | "FLOAT";

  constructor(
    public translate: TranslateService,
    public platform: Platform,
    private admin: AdminService,
    private user: UserService,
    private config: ConfigService,
    private toast: ToastService,
    private routing: RoutingService,
    private environment: EnvironmentService,
  ) {}

  public ngOnInit(): void {
    this.releaseVersion = this.environment.getVariable("RELEASE");
  }

  public async ionViewDidEnter(): Promise<void> {
    this.user.removeUser();
    await this.getAndParseActionsDefinition();
    await this.getRoundingFactors();
    this.getRevenueMode();
  }

  public goToConfigurations(): void {
    this.routing.goForward([SharedRoutes.configurations]);
  }

  public goToScreenSaver(): void {
    this.routing.goForward([SharedRoutes.screenSaver]);
  }

  public goToQrScanner(queryParams: IQrScannerPageParams): void {
    this.routing.goForward([SharedRoutes.qrScanner], {
      queryParams,
    });
  }

  private async getAndParseActionsDefinition(): Promise<void> {
    const promotions = await this.admin.getPromotions();
    this.promotions = Object.keys(promotions)
      .filter(key => promotions[key])
      .map(key => promotions[key]);
  }

  private async getRoundingFactors(): Promise<void> {
    const resp = await this.admin.getAllClients();
    if (resp.ok === true) {
      const client = resp.response.find(c => String(c.clientId) === this.config.getMountingConfig().clientId);
      this.pointsRounding = client.pointsRoundingCode;
      this.revenueRounding = client.revenueRoundingCode;
    } else {
      this.toast.show(this.translate._(resp.error.message as any), "error");
    }
  }

  private getRevenueMode(): void {
    const resp = this.admin.getClientStore();
    if (resp.ok === true) {
      this.revenueMode = resp.response.revenueModuleMode;
    } else {
      this.toast.show(this.translate._(resp.error.message as any), "error");
    }
  }
}
