/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountCoupon } from './account-coupon';
import { MembershipDetails } from './membership-details';
import { Identifier } from './identifier';
import { AccountBalance } from './account-balance';
import { StateLevel } from './state-level';


export interface AccountDetails { 
    accountBalance?: AccountBalance;
    accountCoupons?: Array<AccountCoupon>;
    accountId?: string;
    identifiers?: Array<Identifier>;
    level?: StateLevel;
    memberships?: Array<MembershipDetails>;
    status?: AccountDetails.StatusEnum;
}
export namespace AccountDetails {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'LOCKED';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum,
        LOCKED: 'LOCKED' as StatusEnum
    };
}


