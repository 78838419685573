/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Identifier { 
    /**
     * code that can be used to identify an account
     */
    code: string;
    /**
     * display value of the identifier e.g. for printing
     */
    displayCode?: string;
    identifierId?: string;
    status?: Identifier.StatusEnum;
    type: Identifier.TypeEnum;
}
export namespace Identifier {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'LOCKED';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum,
        LOCKED: 'LOCKED' as StatusEnum
    };
    export type TypeEnum = 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE';
    export const TypeEnum = {
        APPCODE: 'APPCODE' as TypeEnum,
        CARDCODE: 'CARDCODE' as TypeEnum,
        EXTERNALCODE: 'EXTERNALCODE' as TypeEnum
    };
}


