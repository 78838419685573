import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild, OnInit } from "@angular/core";
import { Platform } from "@ionic/angular";
import jsQR from "jsqr";
import { IDeviceConfig } from "shared/lib/common/interfaces";
import { UtilsService } from "shared/lib/common/services";

@Component({
  selector: "shared-qrscanner",
  templateUrl: "./qrscanner.component.html",
  styleUrls: ["./qrscanner.component.scss"],
})
export class QrscannerComponent implements OnInit, OnChanges, OnDestroy {
  private requestAnimationFrameId: number;
  private scanerEnable: boolean;

  public scanerVisible: boolean;

  @Input() public scannerEnabled: boolean;
  @Input() public device: any;
  @Input() public deviceConfig: IDeviceConfig;
  @Input() public cameraPosition: "top" | "left" | "right";
  @Output() public scanSuccess: EventEmitter<any> = new EventEmitter();
  @Output() public scanFailure: EventEmitter<any> = new EventEmitter();
  @Output() public camerasFound: EventEmitter<MediaDeviceInfo[]> = new EventEmitter();
  @Output() public hasDevices: EventEmitter<boolean> = new EventEmitter();
  @Output() public permissionResponse: EventEmitter<boolean> = new EventEmitter();
  @ViewChild("canvas", { static: true }) public canvasElement: any;
  @ViewChild("video", { static: true }) public video: ElementRef;
  public canvas: CanvasRenderingContext2D;
  public code: string;

  constructor(private utils: UtilsService, private platform: Platform) {
    this.tick = this.tick.bind(this);
  }

  public ngOnInit(): void {
    this.findDevices();
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.device && changes.device.currentValue) this.scannerOn();
  }

  public ngOnDestroy(): void {
    this.scannerOff();
  }

  private async findDevices(): Promise<void> {
    const devices = await this.getAnyVideoDevice();
    if (!devices) this.findDevices();
    this.camerasFound.emit(devices);
  }

  private async scannerOn(): Promise<void> {
    this.scanerEnable = true;
    try {
      this.canvas = this.canvasElement && this.canvasElement.nativeElement.getContext("2d");
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: this.device.deviceId },
      });
      this.video.nativeElement.srcObject = mediaStream;
      this.video.nativeElement.setAttribute("playsinline", "true"); // required to tell iOS safari we don't want fullscreen
      this.video.nativeElement.addEventListener("loadedmetadata", () => {
        setTimeout(() => {
          this.onCapabilitiesReady(mediaStream.getTracks()[0].getCapabilities());
          this.scanerVisible = true;
        }, 500);
      });
      this.video.nativeElement
        .play()
        .then(this.tick)
        .catch((e: any) => {});
    } catch (error) {
      console.error(error);
    }
  }

  private async tick(): Promise<void> {
    try {
      if (this.scannerEnabled && this.scanerEnable && this.video.nativeElement.readyState === this.video.nativeElement.HAVE_ENOUGH_DATA) {
        this.canvas.canvas.height = window.innerHeight;
        this.canvas.canvas.width = window.innerWidth;
        this.canvasElement.height = window.innerHeight;
        this.canvasElement.width = window.innerWidth;
        this.canvas.drawImage(this.video.nativeElement, 0, 0, this.canvasElement.width, this.canvasElement.height);
        const imageData = this.canvas.getImageData(0, 0, this.canvasElement.width, this.canvasElement.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert",
        });
        if (code) {
          this.code = code.data;
          this.scanSuccess.emit(this.code);
          await this.utils.waiting(1000);
        }
      }
      if (this.scanerEnable) this.requestAnimationFrameId = requestAnimationFrame(this.tick);
    } catch (error) {
      console.error(error);
    }
  }

  private scannerOff(): void {
    try {
      this.scanerEnable = false;
      this.scanerVisible = false;
      cancelAnimationFrame(this.requestAnimationFrameId);
      this.video.nativeElement.pause();
      this.video.nativeElement.src = "";
      if (this.video.nativeElement.srcObject) (this.video.nativeElement.srcObject as MediaStream).getTracks()[0].stop();
    } catch (error) {
      console.error(error);
    }
  }

  private async getAnyVideoDevice(): Promise<MediaDeviceInfo[]> {
    try {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        this.hasDevices.emit(false);
        return null;
      }
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = (devices || []).filter(d => d.kind === "videoinput");
      if (videoDevices.length === 0) {
        this.hasDevices.emit(false);
        return null;
      }
      this.hasDevices.emit(true);
      return videoDevices;
    } catch (error) {
      console.error(error);
    }
  }

  private onCapabilitiesReady(capabilities: any): void {
    try {
      if (capabilities.exposureCompensation) {
        const { exposureCompensation, zoom } = this.deviceConfig;
        (this.video.nativeElement.srcObject as MediaStream).getTracks()[0].applyConstraints({
          advanced: [{ exposureCompensation, zoom }],
          height: 1080,
          width: 1920,
        } as any);
      }
    } catch (error) {}
  }
}
