import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ConfigService } from "../config/config.service";

@Injectable({
  providedIn: "root",
})
export class InactivityService {
  private idleTime: number;
  private timeout: number;

  private idleInterval: NodeJS.Timeout;
  public timeout$: Subject<void>;
  constructor(private config: ConfigService) {
    this.timeout = this.config.inactivityTime;
    this.timeout$ = new Subject();
    this.incrementTime = this.incrementTime.bind(this);
    window.addEventListener("keyup", () => this.resetTimer());
    window.addEventListener("click", () => this.resetTimer());
    window.addEventListener("wheel", () => this.resetTimer());
  }

  public startWatching(): void {
    this.resetTimer();
    if (this.idleInterval) this.stopWatch();
    this.idleTime = 0;
    this.incrementTime();
    this.idleInterval = setInterval(this.incrementTime, 1000);
  }

  public resetTimer(): void {
    this.idleTime = 0;
  }

  public stopWatch(): void {
    clearInterval(this.idleInterval);
  }

  public onTimeout(): Subject<void> {
    return this.timeout$;
  }

  private incrementTime(): void {
    this.idleTime = this.idleTime + 1;
    if (this.idleTime >= this.timeout) {
      this.resetTimer();
      this.timeout$.next();
    }
  }
}
