import { Component, Input, OnInit } from "@angular/core";
import { Item } from "shared/lib/v2/apis/programs/model/item";
import { ModalController } from "@ionic/angular";
import { IButton } from "shared/lib/common/components";
import { TranslateService } from "shared/lib/common/services";
import { DeviceMountingService } from "shared/lib/v2/services";
import { ItemDetail, Group, SubGroup } from "../../apis/programs";

export interface CalculatorPageInputs {
  item: Group | SubGroup | ItemDetail;
}

export interface CalculatorPageOutputs {
  data?: number;
}

@Component({
  selector: "shared-v2-calculator",
  templateUrl: "./calculator.page.html",
  styleUrls: ["./calculator.page.scss"],
})
export class CalculatorPage implements OnInit {
  @Input() public item: Item;

  public decimalRevenue: boolean;
  public value: number;

  constructor(private deviceMounting: DeviceMountingService, public modal: ModalController, public t: TranslateService) {}

  public ngOnInit(): void {
    this.value = 0;
    if (this.deviceMounting && this.deviceMounting.profile) this.decimalRevenue = this.deviceMounting.profile.decimalRevenue;
  }

  public press(value: IButton): void {
    let v: string;
    switch (value) {
      case "plusMinus":
        this.value = this.value * -1;
        break;
      case "delete":
        if (this.decimalRevenue) {
          v = (this.value * 100).toFixed(0);
          v = v.substring(0, v.length - 1) || "0";
          if (v === "-") v = "0";
          this.value = parseInt(v, 10) / 100;
        } else {
          v = this.value.toFixed(0);
          v = v.substring(0, v.length - 1) || "0";
          if (v === "-") v = "0";
          this.value = parseInt(v, 10);
        }
        break;
      case "c":
        this.value = 0;
        break;
      default:
        if (this.decimalRevenue) {
          v = (this.value * 100).toFixed(0);
          v += value;
          this.value = parseInt(v, 10) / 100;
        } else {
          v = this.value.toFixed(0);
          v += value;
          this.value = parseInt(v, 10);
        }
        break;
    }
  }
}
