import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd } from "@angular/router";
import { SharedRoutes } from "shared/lib/common/enums";
import { ConfigService, RoutingService } from "shared/lib/common/services";
import { Subscription } from "rxjs";

@Component({
  selector: "shared-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private routerEvents: Subscription;
  public timeout: NodeJS.Timeout;
  public adminZoneEnabled: boolean;
  public isScreenSaver: boolean;
  public isNotFound: boolean;

  constructor(private routing: RoutingService, public config: ConfigService) {
    this.adminZoneEnabled = true;
    this.isScreenSaver = false;
    this.isNotFound = false;
  }

  public ngOnInit(): void {
    this.routerEvents = this.routing.getRouterEvents().subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.isScreenSaver = e.url.includes(SharedRoutes.screenSaver);
        this.isNotFound = !e.url.length;
      }
    });
    const segments = this.routing.getUrlSegments();
    this.isNotFound = segments && !segments.length;
  }

  public ngOnDestroy(): void {
    clearTimeout(this.timeout);
    this.routerEvents.unsubscribe();
  }

  public press(): void {
    if (this.adminZoneEnabled) {
      this.adminZoneEnabled = false;
      this.routing.goForward([SharedRoutes.admin]);
      this.timeout = setTimeout(() => {
        this.adminZoneEnabled = true;
      }, 500);
    }
  }
}
