import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { IVoucher } from "shared/lib/common/interfaces";
import { UserService } from "shared/lib/common/services";

export interface ICouponCardInput {
  ticketSelected: IVoucher;
  showRegisterButton: boolean;
  registerMandatory: boolean;
}

export type CouponCardEvent = "redeem" | "activation";

export interface ICouponCardOutput {
  data?: { voucher: IVoucher; event: CouponCardEvent };
}

@Component({
  selector: "shared-v2-coupon-card-page",
  templateUrl: "./coupon-card.page.html",
  styleUrls: ["./coupon-card.page.scss"],
})
export class CouponCardPage {
  @Input() public ticketSelected: IVoucher;
  @Input() public showRegisterButton: boolean;
  @Input() public registerMandatory: boolean;

  constructor(public modal: ModalController, public user: UserService) {}

  public activate(voucher: IVoucher): void {
    this.modal.dismiss({ voucher, event: "activation" });
  }

  public redeem(voucher: IVoucher): void {
    this.modal.dismiss({ voucher, event: "redeem" });
  }
}
