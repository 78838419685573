import { Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { ToastService, TranslateService, ConfigService } from "shared/lib/common/services";
import { AdminService } from "shared/lib/v2/services";
import { EnsureUnmountLoginModal as SharedEnsureUnmountLoginModal } from "shared/lib/common/pages";

export interface EnsureUnmountLoginModalOutputs {
  email: string;
  password: string;
}

@Component({
  selector: "shared-v2-ensure-unmount-login-modal",
  templateUrl: "../../../../../shared/lib/common/pages/ensure-unmount-login-modal/ensure-unmount-login-modal.page.html",
  styleUrls: ["../../../../../shared/lib/common/pages/ensure-unmount-login-modal/ensure-unmount-login-modal.page.scss"],
})
export class EnsureUnmountLoginModal extends SharedEnsureUnmountLoginModal {
  constructor(
    public _translate: TranslateService,
    public _fb: FormBuilder,
    public _admin: AdminService,
    public _config: ConfigService,
    public _toast: ToastService,
    public _modal: ModalController,
  ) {
    super(_translate, _fb, _admin, _config, _toast, _modal);
  }
}
