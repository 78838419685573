import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { IVoucher } from "shared/lib/common/interfaces";
import { PrintService, TranslateService, UtilsService } from "shared/lib/common/services";

export interface IPrintPageOutputs {
  data?: boolean;
}

export interface IPrintPageInputs {
  voucher: IVoucher;
}

@Component({
  selector: "generic-print",
  templateUrl: "./print.page.html",
  styleUrls: ["./print.page.scss"],
})
export class PrintPage implements OnInit {
  public printerStatus: "printing" | "no_paper" | "no_conection" | "success";
  public params: IPrintPageInputs;

  constructor(
    public translate: TranslateService,
    private modal: ModalController,
    private print: PrintService,
    private navParams: NavParams,
    private utils: UtilsService,
  ) {}

  public ngOnInit(): void {
    this.params = this.navParams.data as IPrintPageInputs;
    this.printerStatus = "printing";
    this.printTicket();
  }

  public dismiss(): void {
    this.modal.dismiss(this.printerStatus === "success");
  }

  private async printTicket(): Promise<void> {
    const response = await this.print.printTicket(this.params.voucher);
    if (response.ok === true) {
      this.printerStatus = "success";
    } else {
      if (response.error.message === "NOT_CONNECTED") {
        this.printerStatus = "no_conection";
      } else {
        this.printerStatus = "no_paper";
      }
    }
    await this.utils.waiting(10000);
    this.dismiss();
  }
}
