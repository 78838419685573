/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * external Code readable by POS
 */
export interface ExternalCode { 
    type?: ExternalCode.TypeEnum;
    value?: string;
}
export namespace ExternalCode {
    export type TypeEnum = 'TEXT' | 'ENDPOINT';
    export const TypeEnum = {
        TEXT: 'TEXT' as TypeEnum,
        ENDPOINT: 'ENDPOINT' as TypeEnum
    };
}


