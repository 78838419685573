<ion-content scroll-y="false">
  <div class="page-wrapper print">
    <div class="print__content">
      <h1 class="print__content__title">
        {{ translate._("PRINT_PAGE_TITLE") }}
      </h1>
      <div class="print__content__printing" *ngIf="printerStatus === 'printing'">
        <i-printer class="print__content__printing__icon"></i-printer>
      </div>
      <div class="print__content__no-paper" *ngIf="printerStatus === 'no_paper'">
        <div class="print__content__no-paper__icons-wrapper">
          <i-map class="print__content__no-paper__icons-wrapper__paper-icon"> </i-map>
          <i-x class="print__content__no-paper__icons-wrapper__close-icon"></i-x>
        </div>
        <p class="print__content__no-paper__text">
          {{ translate._("NO_PAPER_TEXT1") }}
          <br />
          {{ translate._("NO_PAPER_TEXT2") }}
        </p>
      </div>
      <div class="print__content__no-connection" *ngIf="printerStatus === 'no_conection'">
        <div class="print__content__no-connection__icons-wrapper">
          <i-printer class="print__content__no-connection__icons-wrapper__printer-icon"> </i-printer>
          <i-x class="print__content__no-connection__icons-wrapper__close-icon"></i-x>
        </div>
        <p class="print__content__no-connection__text">
          {{ translate._("NO_CONNECTION_TEXT1") }}
          <br />
          {{ translate._("NO_CONNECTION_TEXT2") }}
        </p>
      </div>
      <div class="print__content__success" *ngIf="printerStatus === 'success'">
        <div class="print__content__success__icons-wrapper">
          <i-printer class="print__content__success__icons-wrapper__printer-icon"> </i-printer>
          <i-check class="print__content__success__icons-wrapper__success-icon"></i-check>
        </div>
        <p class="print__content__success__text">
          {{ translate._("SUCCESS_TEXT1") }}
          <br />
          {{ translate._("SUCCESS_TEXT2") }}
        </p>
      </div>
      <ion-button
        class="button button--secondary print__content__button"
        color="secondary"
        fill="solid"
        (tap)="printerStatus !== 'printing' ? dismiss() : null"
        ><h6 *ngIf="printerStatus !== 'printing'">{{ translate._("OK") }}</h6>
        <ion-spinner *ngIf="printerStatus === 'printing'" class="print__content__button__spinner" name="dots"></ion-spinner
      ></ion-button>
    </div>
  </div>
</ion-content>
