import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IVoucher } from "shared/lib/common/interfaces";
import { TranslateService, UtilsService } from "shared/lib/common/services";

@Component({
  selector: "shared-v1-coupon-card",
  templateUrl: "./coupon-card.component.html",
  styleUrls: ["./coupon-card.component.scss"],
})
export class CouponCardComponent implements OnInit {
  @Input() public ticketSelected: IVoucher;
  @Input() public showRegisterButton: boolean;
  @Input() public isCouponRegisterMandatory: boolean;
  @Input() public isCouponLocked: boolean;
  @Input() public loading: boolean;
  @Output() public redeemEvent: EventEmitter<IVoucher> = new EventEmitter();
  @Output() public registerEvent: EventEmitter<void> = new EventEmitter();

  public buttonDisabled: boolean;

  constructor(public translate: TranslateService, public utils: UtilsService) {}

  public ngOnInit(): void {
    this.buttonDisabled = false;
  }

  public async redeem(coupon: IVoucher): Promise<void> {
    this.buttonDisabled = true;
    this.redeemEvent.emit(coupon);
    await this.utils.waiting(1000);
    this.buttonDisabled = false;
  }

  protected goToRegister(): void {
    this.registerEvent.emit();
  }
}
