import { Component } from "@angular/core";
import { EnvironmentService, P4mService } from "shared/lib/common/services";
import { ConfigService } from "shared/lib/common/services";

@Component({
  selector: "generic-device-test",
  templateUrl: "./device-test.page.html",
  styleUrls: ["./device-test.page.scss"],
})
export class DeviceTestPage {
  public cameraPermissionState: string;
  public releaseVersion: string;
  public organisation: string;
  public mountingUser: string;

  constructor(private config: ConfigService, private p4m: P4mService, private environment: EnvironmentService) {}

  public async ionViewDidEnter(): Promise<void> {
    this.releaseVersion = this.environment.getVariable("RELEASE");
    this.organisation = this.config.getOrganization();
    this.mountingUser = this.p4m.getUser();
    navigator.getUserMedia({ video: true }, _ => (this.cameraPermissionState = "granted"), _ => (this.cameraPermissionState = "denied"));
  }
}
