import { Component, OnInit } from "@angular/core";
import { IVoucher } from "shared/lib/common/interfaces";
import { TranslateService } from "shared/lib/common/services";
import { NavParams, ModalController } from "@ionic/angular";

export interface IConfirmationPageInputs {
  title: string;
  confirmText: string;
  dismissText: string;
  subtitle?: string;
  voucher?: IVoucher;
}

export interface IConfirmationPageOutputs {
  data?: boolean;
}

@Component({
  selector: "shared-confirmation",
  templateUrl: "./confirmation.page.html",
  styleUrls: ["./confirmation.page.scss"],
})
export class ConfirmationPage implements OnInit {
  public params: IConfirmationPageInputs;

  constructor(private navParams: NavParams, private modal: ModalController, public translate: TranslateService) {}

  public ngOnInit(): void {
    this.params = this.navParams.data as IConfirmationPageInputs;
  }

  public async cancelation(): Promise<void> {
    await this.modal.dismiss(false);
  }

  public async confirmation(): Promise<void> {
    await this.modal.dismiss(true);
  }
}
