<ion-content>
  <div class="page-wrapper configurations">
    <shared-close class="configurations__shared-close" (tap)="back()"></shared-close>
    <div class="configurations__content">
      <form class="configurations__content__printer" autocomplete="off" [formGroup]="form">
        <h1 class="configurations__content__printer__title">
          {{ translate._("PRINTER_CONFIG_TITLE") }}
        </h1>
        <h2 class="configurations__content__printer__ip">
          {{ translate._("PRINTER_CONFIG_SUBTITLE") }}
        </h2>
        <div class="configurations__content__printer__ip-input-container">
          <input
            [value]="form.get('ip').value"
            formControlName="ip"
            autocomplete="off"
            placeholder="192.168.1.134"
            class="input configurations__content__printer__ip-input"
          />
        </div>
        <div class="configurations__content__printer__buttons-container">
          <shared-button
            class="configurations__content__printer__buttons-container__remove"
            (clickEvent)="removeIp()"
            [disabled]="!print.getIp()"
            [color]="'danger'"
            [fill]="'solid'"
            [disabled]="form.get('ip').invalid"
            >{{ translate._("REMOVE_PRINTER") }}</shared-button
          >
          <shared-button
            class="configurations__content__printer__buttons-container__save"
            [color]="'primary'"
            [fill]="'solid'"
            [disabled]="form.get('ip').invalid"
            [loading]="loading"
            (clickEvent)="testConnection(form.get('ip').value)"
            >{{ translate._("PRINTER_CONFIG_BUTTON") }}
          </shared-button>
        </div>
      </form>
      <div class="configurations__content__admin">
        <h1 class="configurations__content__admin__title">
          {{ translate._("GENERAL_SETTINGS") }}
        </h1>
        <shared-button
          *ngIf="config.showSelfServiceButton"
          class="configurations__content__admin__button"
          [color]="'dark'"
          [fill]="'solid'"
          (tap)="openBackOffice()"
        >
          {{ translate._("BACKOFFICE") }}
        </shared-button>
        <shared-button
          class="configurations__content__admin__button"
          [color]="'danger'"
          [fill]="'solid'"
          [loading]="unmmountLoading"
          (tap)="logOut()"
        >
          {{ translate._("UNMOUNT_DEVICE") }}
        </shared-button>
      </div>
    </div>
  </div>
</ion-content>
