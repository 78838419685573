/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./qr-scanner.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../v1/components/qrscanner/qrscanner.component.ngfactory";
import * as i3 from "../../../v1/components/qrscanner/qrscanner.component";
import * as i4 from "../../services/utils/utils.service";
import * as i5 from "@ionic/angular";
import * as i6 from "../../icons/devices/devices.component.ngfactory";
import * as i7 from "../../icons/devices/devices.component";
import * as i8 from "@angular/common";
import * as i9 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i10 from "../../icons/scan-frame/scan-frame.component.ngfactory";
import * as i11 from "../../icons/scan-frame/scan-frame.component";
import * as i12 from "../../../v1/components/close/close.component.ngfactory";
import * as i13 from "../../../v1/components/close/close.component";
import * as i14 from "./qr-scanner.page";
import * as i15 from "../../services/translate/translate.service";
import * as i16 from "../../services/user/user.service";
import * as i17 from "../../services/toast/toast.service";
import * as i18 from "../../services/config/config.service";
import * as i19 from "../../services/admin/admin.service";
import * as i20 from "../../services/inactivity/inactivity.service";
import * as i21 from "@ionic-native/qr-scanner/ngx/index";
import * as i22 from "../../services/routing/routing.service";
import * as i23 from "../../services/alert/alert.service";
var styles_QrScannerPage = [i0.styles];
var RenderType_QrScannerPage = i1.ɵcrt({ encapsulation: 0, styles: styles_QrScannerPage, data: {} });
export { RenderType_QrScannerPage as RenderType_QrScannerPage };
function View_QrScannerPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-qrscanner", [["class", "qr-scan__content__scanner-wrapper__scanner"]], null, [[null, "torchCompatible"], [null, "hasDevices"], [null, "scanError"], [null, "scanComplete"], [null, "permissionResponse"], [null, "camerasFound"], [null, "scanSuccess"], [null, "scanFailure"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("torchCompatible" === en)) {
        var pd_0 = (_co.logs("torchCompatible", $event) !== false);
        ad = (pd_0 && ad);
    } if (("hasDevices" === en)) {
        var pd_1 = (_co.logs("hasDevices", $event) !== false);
        ad = (pd_1 && ad);
    } if (("scanError" === en)) {
        var pd_2 = (_co.logs("scanError", $event) !== false);
        ad = (pd_2 && ad);
    } if (("scanComplete" === en)) {
        var pd_3 = (_co.logs("scanComplete", $event) !== false);
        ad = (pd_3 && ad);
    } if (("permissionResponse" === en)) {
        var pd_4 = (_co.logs("permissionResponse", $event) !== false);
        ad = (pd_4 && ad);
    } if (("camerasFound" === en)) {
        var pd_5 = (_co.cameraFounds($event) !== false);
        ad = (pd_5 && ad);
    } if (("scanSuccess" === en)) {
        var pd_6 = (_co.scannerCode($event, false) !== false);
        ad = (pd_6 && ad);
    } if (("scanFailure" === en)) {
        var pd_7 = (_co.scannerCode($event, true) !== false);
        ad = (pd_7 && ad);
    } return ad; }, i2.View_QrscannerComponent_0, i2.RenderType_QrscannerComponent)), i1.ɵdid(1, 770048, null, 0, i3.QrscannerComponent, [i4.UtilsService, i5.Platform], { scannerEnabled: [0, "scannerEnabled"], device: [1, "device"], deviceConfig: [2, "deviceConfig"], cameraPosition: [3, "cameraPosition"] }, { scanSuccess: "scanSuccess", scanFailure: "scanFailure", camerasFound: "camerasFound", hasDevices: "hasDevices", permissionResponse: "permissionResponse" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.scannerEnabled; var currVal_1 = _co.cameraSelected; var currVal_2 = _co.deviceConfig; var currVal_3 = _co.cameraPosition; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_QrScannerPage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-devices", [], [[8, "className", 0]], null, null, i6.View_DevicesComponent_0, i6.RenderType_DevicesComponent)), i1.ɵdid(1, 114688, null, 0, i7.DevicesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "qr-scan__content__wrapper__image --", _co.cameraPosition, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_QrScannerPage_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["class", "qr-scan__content__wrapper__earn__points"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵppd(3, 3), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "qr-scan__content__wrapper__earn__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "qr-scan__content__wrapper__earn --", _co.cameraPosition, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), ((_co.params["totalPoints"] || _co.params["manualPoints"]) || _co.params["acumulateParams"]), "", _co.translate.getSessionLanguage())); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.translate._("ACCUMULATE_POINTS"); _ck(_v, 5, 0, currVal_2); }); }
export function View_QrScannerPage_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i8.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 17, "ion-content", [["class", "qr-scan page-wrapper"], ["scroll-y", "false"]], [[2, "--hide-background", null], [2, "--capacitor", null]], null, null, i9.View_IonContent_0, i9.RenderType_IonContent)), i1.ɵdid(2, 49152, null, 0, i5.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 13, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QrScannerPage_1)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "shared-scan-frame", [], null, null, null, i10.View_ScanFrameComponent_0, i10.RenderType_ScanFrameComponent)), i1.ɵdid(9, 114688, null, 0, i11.ScanFrameComponent, [], { loading: [0, "loading"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 6, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "h1", [["class", "qr-scan__content__wrapper__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QrScannerPage_2)), i1.ɵdid(14, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QrScannerPage_3)), i1.ɵdid(16, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, 0, 1, "shared-close", [], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_CloseComponent_0, i12.RenderType_CloseComponent)), i1.ɵdid(18, 49152, null, 0, i13.CloseComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = (!_co.isOnCapacitor && _co.showCamera); _ck(_v, 6, 0, currVal_4); var currVal_6 = _co.loading; _ck(_v, 9, 0, currVal_6); var currVal_9 = (((_co.params == null) ? null : _co.params.type) === "burn"); _ck(_v, 14, 0, currVal_9); var currVal_10 = (((_co.params == null) ? null : _co.params.type) === "earn"); _ck(_v, 16, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.scannerEnabled && _co.isOnCapacitor); var currVal_1 = _co.isOnCapacitor; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "qr-scan__content --", _co.cameraPosition, ""); _ck(_v, 3, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "qr-scan__content__scanner-wrapper --", _co.cameraPosition, ""); _ck(_v, 4, 0, currVal_3); var currVal_5 = i1.ɵinlineInterpolate(1, "qr-scan__content__scanner-wrapper__frame --", _co.cameraPosition, ""); _ck(_v, 7, 0, currVal_5); var currVal_7 = i1.ɵinlineInterpolate(1, "qr-scan__content__wrapper --", _co.cameraPosition, ""); _ck(_v, 10, 0, currVal_7); var currVal_8 = _co.translate._("LOYALTY_CARD"); _ck(_v, 12, 0, currVal_8); }); }
export function View_QrScannerPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-qr-scanner", [], null, null, null, View_QrScannerPage_0, RenderType_QrScannerPage)), i1.ɵdid(1, 49152, null, 0, i14.QrScannerPage, [i1.NgZone, i15.TranslateService, i16.UserService, i17.ToastService, i18.ConfigService, i5.ModalController, i19.AdminService, i20.InactivityService, i5.Platform, i21.QRScanner, i22.RoutingService, i23.AlertService], null, null)], null, null); }
var QrScannerPageNgFactory = i1.ɵccf("shared-qr-scanner", i14.QrScannerPage, View_QrScannerPage_Host_0, {}, {}, []);
export { QrScannerPageNgFactory as QrScannerPageNgFactory };
