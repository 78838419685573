import { Component, Input, EventEmitter, Output } from "@angular/core";
import { TranslateService } from "shared/lib/common/services";

@Component({
  selector: "shared-v2-summary-footer",
  templateUrl: "./summary-footer.component.html",
  styleUrls: ["./summary-footer.component.scss"],
})
export class SummaryFooterComponent {
  @Input() public revenue: number;
  @Input() public points: number;
  @Input() public buttonDirection: "UP" | "DOWN";
  @Input() public decimalRevenue: boolean;
  @Output() public clickEvent: EventEmitter<any> = new EventEmitter();
  @Output() public checkOutEvent: EventEmitter<any> = new EventEmitter();

  constructor(public t: TranslateService) {}
}
