<shared-close (tap)="back()"></shared-close>
<ion-content scroll-y="false">
  <div class="page-wrapper login">
    <div class="login__content">
      <h1 class="login__content__title">{{ translate._("LOGIN") }}</h1>
      <form
        autocomplete="off"
        [formGroup]="form"
        class="login__content__form"
        (keyup.enter)="!form.invalid && login(form.get('email').value, form.get('password').value)"
      >
        <input
          (focus)="updateKeyboardStatus(true)"
          (focusout)="updateKeyboardStatus(false)"
          autocomplete="off"
          type="email"
          formControlName="email"
          placeholder="Email"
          class="login__content__form__input"
          [class.--has-error]="checkErrors('email')"
        />
        <div class="login__content__form__password">
          <input
            (focus)="updateKeyboardStatus(true)"
            (focusout)="updateKeyboardStatus(false)"
            autocomplete="off"
            formControlName="password"
            [type]="showPassword ? 'text' : 'password'"
            placeholder="Password"
            class="login__content__form__password__input"
            [class.--has-error]="checkErrors('password')"
          />
          <ion-button (tap)="changeShowPassword()" class="login__content__form__password__button" fill="clear" color="dark">
            <i-eye *ngIf="!showPassword" class="login__content__form__password__button__icon"></i-eye>
            <i-eye-off *ngIf="showPassword" class="login__content__form__password__button__icon"></i-eye-off>
          </ion-button>
        </div>
        <shared-button
          class="login__content__form__button"
          [color]="'primary'"
          [fill]="'solid'"
          [disabled]="!form.valid"
          [loading]="loading"
          (clickEvent)="login(form.get('email').value, form.get('password').value)"
        >
          {{ translate._("LOGIN") }}</shared-button
        >
      </form>
    </div>
  </div>
</ion-content>
