import { Injectable, Inject, Optional } from "@angular/core";
import { Configuration, BASE_PATH } from "shared/lib/v2/apis/mounting-service";
import { HttpClient } from "@angular/common/http";
import { EnvironmentService } from "shared/lib/common/services";
import { DevicesService as SharedDevicesService } from "shared/lib/v2/apis/mounting-service";

@Injectable({ providedIn: "root" })
export class DevicesService extends SharedDevicesService {
  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    protected environmentService: EnvironmentService,
  ) {
    super(httpClient, environmentService.getVariable("API_V2"), configuration);
  }
}
