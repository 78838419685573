export type CreateDeviceResult =
  | "OK"
  | "DEVICE_NOT_FOUND"
  | "DEVICE_IN_USE"
  | "DEVICE_OPEN_ERROR"
  | "DEVICE_TYPE_INVALID"
  | "PARAM_ERROR"
  | "SYSTEM_ERROR";

export type ConnectionResult = "OK" | "SSL_CONNECT_OK" | "ERROR_TIMEOUT" | "ERROR_PARAMETER";

export enum Align {
  center = "center",
  left = "left",
  right = "right",
}

export enum Line {
  title = "title",
  subtitle = "subtitle",
  paragraph = "paragraph",
}

interface CreateDeviceOptions {
  crypto: boolean;
  buffer: boolean;
}

export interface IPrinter {
  onreceive: () => void;
  addCut: () => void;
  send: () => void;
  addTextLang: (lang: string) => void;
  addTextAlign: (align: Align) => void;
  addTextStyle: (reverse: boolean, ul: boolean, em: boolean, color?: boolean) => void;
  addTextSize: (width: number, height: number) => void;
  addText: (text: string) => void;
  addSymbol: (
    data: string,
    type: "qrcode_micro",
    level: "level_0",
    width: 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16,
  ) => void;
  addBarcode: (
    data: string,
    type: "code128" | "ean13" | "ean8",
    hri: "none",
    font: "font_a",
    width: 3, // 2 - 6
    height: 162, // 1 - 255
  ) => void;
  addImage: (
    context: CanvasRenderingContext2D,
    x: number, // 0 - 65535
    y: number, // 0 - 65535
    width: number, // 0 - 65535
    height: number, // 0 - 65535
    color: "none" | "color_1" | "color_2" | "color_3" | "color_4",
    mode: "gray16" | "mono",
  ) => void;
  brightness: number;
  halftone: number;
}

export class EPOSDevice {
  public connect: (ipAddress: string, port: number, connectCallback: (result: ConnectionResult) => void) => void;
  public createDevice: (
    deviceId: string,
    deviceType: string,
    options: CreateDeviceOptions,
    createDeviceCallback: (device: IPrinter, result: CreateDeviceResult) => void,
  ) => void;
  public deleteDevice: (printer: IPrinter, deleteDeviceCallback: () => void) => void;
  public disconnect: () => void;
  constructor() {}
}
