/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Optin } from './optin';


export interface Membership { 
    accountId?: string;
    createdAt?: string;
    memberRole?: Membership.MemberRoleEnum;
    membershipId?: string;
    optins?: Array<Optin>;
    partnerId?: string;
    terminated?: boolean;
    userId?: string;
}
export namespace Membership {
    export type MemberRoleEnum = 'OWNER' | 'COOWNER' | 'COLLECTOR';
    export const MemberRoleEnum = {
        OWNER: 'OWNER' as MemberRoleEnum,
        COOWNER: 'COOWNER' as MemberRoleEnum,
        COLLECTOR: 'COLLECTOR' as MemberRoleEnum
    };
}


