import { Component, Input, Output, EventEmitter } from "@angular/core";

export type IButton = "plusMinus" | "c" | "ac" | "delete" | "enter" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "0" | "00";

@Component({
  selector: "shared-keypad",
  templateUrl: "./keypad.component.html",
  styleUrls: ["./keypad.component.scss"],
})
export class KeypadComponent {
  @Input() public disabled: boolean;
  @Input() public buttons: IButton[];
  @Output() public event: EventEmitter<IButton> = new EventEmitter();

  public onPress(key: IButton): void {
    this.event.emit(key);
  }

  public isNumber(number: string): boolean {
    return ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "00"].some(n => n === number);
  }
}
