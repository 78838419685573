<ion-content class="modal--inner infomodal">
  <div class="infomodal--header">
    <h1 class="modal--title infomodal--title">{{ title }}</h1>
  </div>
  <div class="infomodal--content">
    <p>
      {{ description }}
    </p>
  </div>
  <div class="modal--footer infomodal--footer">
    <ion-button
      class="button button--secondary"
      color="secondary"
      fill="solid"
      expand="block"
      (tap)="dismiss()"
    >
      {{ buttonText }}
    </ion-button>
  </div>
</ion-content>
