<svg
  class="tap"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  width="50px"
  height="50px"
  viewBox="0 0 50 50"
  enable-background="new 0 0 50 50"
  xml:space="preserve"
>
  <path
    class="tap--path"
    d="M46.75,19.853l-8.545-9.391c-1.691-1.691-4.445-1.693-6.152,0.014c-0.541,0.541-0.91,1.188-1.1,1.879
            	c-1.701-1.302-4.152-1.173-5.719,0.394c-0.539,0.541-0.907,1.188-1.103,1.877c-1.7-1.298-4.152-1.17-5.716,0.396
            	c-0.53,0.53-0.893,1.161-1.091,1.834l-4.027-4.027c-1.691-1.691-4.445-1.693-6.152,0.014c-1.693,1.691-1.693,4.445,0,6.139
            	l15.473,15.473l-9.177,1.44c-2.479,0.355-4.35,2.51-4.35,5.017c0,1.88,1.53,3.409,3.409,3.409h21.43
            	c3.338,0,6.477-1.301,8.838-3.662l3.576-3.574C48.702,34.725,50,31.589,50,28.254C50,25.141,48.846,22.157,46.75,19.853z
            	 M44.736,35.475l-3.574,3.575c-1.933,1.933-4.5,2.995-7.232,2.995H12.5c-0.627,0-1.136-0.509-1.136-1.136
            	c0-1.382,1.032-2.57,2.416-2.769l11.398-1.791c0.422-0.066,0.77-0.363,0.905-0.77c0.131-0.405,0.025-0.854-0.277-1.155L8.752,17.373
            	c-0.807-0.807-0.807-2.12,0.011-2.941c0.405-0.402,0.934-0.604,1.464-0.604c0.529,0,1.059,0.203,1.463,0.607l11.371,11.371
            	c0.443,0.443,1.164,0.443,1.607,0C24.889,25.582,25,25.291,25,25s-0.112-0.582-0.334-0.805l-4.643-4.643
            	c-0.807-0.807-0.807-2.118,0.014-2.938c0.805-0.805,2.121-0.805,2.925,0l4.646,4.646c0.443,0.443,1.163,0.443,1.606,0
            	c0.22-0.223,0.332-0.514,0.332-0.805s-0.111-0.582-0.334-0.805l-2.37-2.371c-0.808-0.807-0.808-2.118,0.014-2.938
            	c0.804-0.805,2.119-0.805,2.924,0l2.383,2.382c0.004,0.002,0.002,0.002,0.002,0.002l0.002,0.002
            	c0.002,0.002,0.002,0.002,0.002,0.002c0.002,0.002,0.002,0.002,0.002,0.002h0.003c0.002,0,0.002,0.002,0.002,0.002
            	c0.446,0.423,1.15,0.414,1.585-0.023c0.442-0.443,0.442-1.164,0-1.606l-0.098-0.098c-0.392-0.391-0.607-0.912-0.607-1.464
            	s0.213-1.07,0.618-1.475c0.81-0.804,2.118-0.802,2.889-0.039l8.509,9.353c1.711,1.884,2.657,4.325,2.657,6.872
            	C47.728,30.982,46.666,33.545,44.736,35.475z"
  />
  <path
    class="tap--path"
    d="M19.536,11.711c-1.654-3.662-5.309-6.03-9.309-6.03C4.589,5.682,0,10.271,0,15.909
            	c0,4,2.368,7.654,6.029,9.312c0.152,0.068,0.312,0.1,0.468,0.1c0.432,0,0.846-0.25,1.037-0.668c0.257-0.573,0.002-1.246-0.568-1.505
            	c-2.85-1.288-4.693-4.129-4.693-7.238c0-4.387,3.568-7.955,7.955-7.955c3.109,0,5.95,1.843,7.239,4.693
            	c0.256,0.573,0.932,0.83,1.502,0.568C19.541,12.957,19.795,12.284,19.536,11.711z"
  />
</svg>
