var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ElementRef, EventEmitter, OnChanges, OnDestroy, SimpleChanges, OnInit } from "@angular/core";
import { Platform } from "@ionic/angular";
import jsQR from "jsqr";
import { UtilsService } from "shared/lib/common/services";
export class QrscannerComponent {
    constructor(utils, platform) {
        this.utils = utils;
        this.platform = platform;
        this.scanSuccess = new EventEmitter();
        this.scanFailure = new EventEmitter();
        this.camerasFound = new EventEmitter();
        this.hasDevices = new EventEmitter();
        this.permissionResponse = new EventEmitter();
        this.tick = this.tick.bind(this);
    }
    ngOnInit() {
        this.findDevices();
    }
    ngOnChanges(changes) {
        if (changes.device && changes.device.currentValue)
            this.scannerOn();
    }
    ngOnDestroy() {
        this.scannerOff();
    }
    findDevices() {
        return __awaiter(this, void 0, void 0, function* () {
            const devices = yield this.getAnyVideoDevice();
            if (!devices)
                this.findDevices();
            this.camerasFound.emit(devices);
        });
    }
    scannerOn() {
        return __awaiter(this, void 0, void 0, function* () {
            this.scanerEnable = true;
            try {
                this.canvas = this.canvasElement && this.canvasElement.nativeElement.getContext("2d");
                const mediaStream = yield navigator.mediaDevices.getUserMedia({
                    video: { deviceId: this.device.deviceId },
                });
                this.video.nativeElement.srcObject = mediaStream;
                this.video.nativeElement.setAttribute("playsinline", "true"); // required to tell iOS safari we don't want fullscreen
                this.video.nativeElement.addEventListener("loadedmetadata", () => {
                    setTimeout(() => {
                        this.onCapabilitiesReady(mediaStream.getTracks()[0].getCapabilities());
                        this.scanerVisible = true;
                    }, 500);
                });
                this.video.nativeElement
                    .play()
                    .then(this.tick)
                    .catch((e) => { });
            }
            catch (error) {
                console.error(error);
            }
        });
    }
    tick() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (this.scannerEnabled && this.scanerEnable && this.video.nativeElement.readyState === this.video.nativeElement.HAVE_ENOUGH_DATA) {
                    this.canvas.canvas.height = window.innerHeight;
                    this.canvas.canvas.width = window.innerWidth;
                    this.canvasElement.height = window.innerHeight;
                    this.canvasElement.width = window.innerWidth;
                    this.canvas.drawImage(this.video.nativeElement, 0, 0, this.canvasElement.width, this.canvasElement.height);
                    const imageData = this.canvas.getImageData(0, 0, this.canvasElement.width, this.canvasElement.height);
                    const code = jsQR(imageData.data, imageData.width, imageData.height, {
                        inversionAttempts: "dontInvert",
                    });
                    if (code) {
                        this.code = code.data;
                        this.scanSuccess.emit(this.code);
                        yield this.utils.waiting(1000);
                    }
                }
                if (this.scanerEnable)
                    this.requestAnimationFrameId = requestAnimationFrame(this.tick);
            }
            catch (error) {
                console.error(error);
            }
        });
    }
    scannerOff() {
        try {
            this.scanerEnable = false;
            this.scanerVisible = false;
            cancelAnimationFrame(this.requestAnimationFrameId);
            this.video.nativeElement.pause();
            this.video.nativeElement.src = "";
            if (this.video.nativeElement.srcObject)
                this.video.nativeElement.srcObject.getTracks()[0].stop();
        }
        catch (error) {
            console.error(error);
        }
    }
    getAnyVideoDevice() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
                    this.hasDevices.emit(false);
                    return null;
                }
                const devices = yield navigator.mediaDevices.enumerateDevices();
                const videoDevices = (devices || []).filter(d => d.kind === "videoinput");
                if (videoDevices.length === 0) {
                    this.hasDevices.emit(false);
                    return null;
                }
                this.hasDevices.emit(true);
                return videoDevices;
            }
            catch (error) {
                console.error(error);
            }
        });
    }
    onCapabilitiesReady(capabilities) {
        try {
            if (capabilities.exposureCompensation) {
                const { exposureCompensation, zoom } = this.deviceConfig;
                this.video.nativeElement.srcObject.getTracks()[0].applyConstraints({
                    advanced: [{ exposureCompensation, zoom }],
                    height: 1080,
                    width: 1920,
                });
            }
        }
        catch (error) { }
    }
}
