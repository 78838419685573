import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { SharedRoutes } from "shared/lib/common/enums";
import { RoutingService } from "../../services";

@Injectable({
  providedIn: "root",
})
export class ComesFromLoginGuard implements CanActivate {
  constructor(private routing: RoutingService) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.routing.isActiveUrl(SharedRoutes.mounting) || this.routing.isActiveUrl(SharedRoutes.login) || this.routing.isActiveUrl("")) {
      return true;
    }
    localStorage.clear();
    this.routing.goForward([SharedRoutes.login]);
    return false;
  }
}
