<ion-content class="devicetest">
  <div class="devicedata">
    Camera Permision State: {{ cameraPermissionState }}
    <br />
    App-Version: {{ releaseVersion }}
    <br />
    Organisation: {{ organisation }}
    <br />
    Mounting User: {{ mountingUser }}
    <br />
  </div>
</ion-content>
