<div class="pin">
  <div class="pin__input">
    <b class="pin__input__text" *ngFor="let item of getArray(pinLength); let i = index" [class.--is-disabled]="i >= pin.length">*</b>
  </div>
  <shared-keypad
    [disabled]="isEnterDisabled()"
    [buttons]="['7', '8', '9', '4', '5', '6', '1', '2', '3', 'delete', '0', 'enter']"
    (event)="press($event)"
  ></shared-keypad>
</div>
