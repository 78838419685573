import { Component, OnInit, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";

export interface IInformationModalPageInputs {
  title: string;
  description: string;
  buttonText: string;
}

@Component({
  selector: "generic-information-modal",
  templateUrl: "./information-modal.page.html",
  styleUrls: ["./information-modal.page.scss"],
})
export class InformationModalPage implements OnInit {
  @Input() public title: string;
  @Input() public description: string;
  @Input() public buttonText: string;

  constructor(private modal: ModalController) {}

  public ngOnInit(): void {}

  public dismiss(): void {
    this.modal.dismiss();
  }
}
