import { Injectable } from "@angular/core";

export enum EVENT_LISTENERS {
  ON_LOAD_EPSON_SCRIPT = "ON_LOAD_EPSON_SCRIPT",
  ON_LOAD_EPSON_JSZUTILS = "ON_LOAD_EPSON_JSZUTILS",
}

const THIRD_PARTY_SCRIPTS: Array<{ path: string; async: boolean; onloadEvent: Event }> = [
  { path: "/assets/epos-2.12.0.js", async: true, onloadEvent: new CustomEvent(EVENT_LISTENERS.ON_LOAD_EPSON_SCRIPT) },
  { path: "/assets/jszip-utils.js", async: false, onloadEvent: new CustomEvent(EVENT_LISTENERS.ON_LOAD_EPSON_JSZUTILS) },
];

@Injectable({
  providedIn: "root",
})
export class ThirdPartyScriptsService {
  constructor() {}

  public async addThirdPartyScripts(): Promise<void> {
    for (const script of THIRD_PARTY_SCRIPTS) {
      await this.loadScript(script);
    }
  }

  private loadScript({ path, async, onloadEvent }: { path: string; async: boolean; onloadEvent: Event }): Promise<void> {
    return new Promise(resolve => {
      const script = document.createElement("script");
      script.setAttribute("src", path);
      script.setAttribute("type", "text/javascript");
      script.onload = () => {
        document.dispatchEvent(onloadEvent);
        resolve();
      };
      document.head.appendChild(script);
      if (async) resolve();
    });
  }
}
