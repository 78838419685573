<div class="summary-footer">
  <i-chevron-down (click)="clickEvent.emit()" class="summary-footer__down" *ngIf="buttonDirection === 'DOWN'">
  </i-chevron-down>
  <i-chevron-up (click)="clickEvent.emit()" class="summary-footer__up" *ngIf="buttonDirection === 'UP'"></i-chevron-up>
  <div (click)="clickEvent.emit()" class="summary-footer__info">
    <h1 class="summary-footer__info__money"
      [innerHTML]="revenue | currency: 'EUR':'symbol':decimalRevenue ? '1.2-2' : '1.0-0':'de'"></h1>
    <p class="summary-footer__info__points" [innerHTML]="points + ' ' +t._('POINTS_TO_EXCHANGE')"></p>
  </div>
  <ion-button (click)="checkOutEvent.emit()" class="summary-footer__button" [disabled]="!revenue"
    [color]="!revenue?'text-disable':'primary'" [fill]="'solid'">
    <span>{{t._("REVENUE_CHECK_OUT")}}</span>
    <ion-icon name="cart"></ion-icon>
  </ion-button>
</div>