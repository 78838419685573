/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PushToken { 
    /**
     * Unique Identifier of the device for which the PushToken is used for
     */
    deviceId: string;
    /**
     * The enviroment where this PushToken is valid and used for
     */
    environment: PushToken.EnvironmentEnum;
    /**
     * The token as String
     */
    token: string;
}
export namespace PushToken {
    export type EnvironmentEnum = 'IOS' | 'ANDROID' | 'OTHER';
    export const EnvironmentEnum = {
        IOS: 'IOS' as EnvironmentEnum,
        ANDROID: 'ANDROID' as EnvironmentEnum,
        OTHER: 'OTHER' as EnvironmentEnum
    };
}


