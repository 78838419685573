/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { Account } from '../model/models';
import { AccountBalance } from '../model/models';
import { AccountCoupon } from '../model/models';
import { AccountTransaction } from '../model/models';
import { Booking } from '../model/models';
import { BookingData } from '../model/models';
import { CurrencyBalance } from '../model/models';
import { Fraud } from '../model/models';
import { FraudData } from '../model/models';
import { Identifier } from '../model/models';
import { IdentifierData } from '../model/models';
import { LevelBooking } from '../model/models';
import { LevelBookingData } from '../model/models';
import { Membership } from '../model/models';
import { MembershipData } from '../model/models';
import { Optin } from '../model/models';
import { PushToken } from '../model/models';
import { PushTokenData } from '../model/models';
import { StateLevel } from '../model/models';
import { TransactionData } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    AccountServiceInterface
} from './account.serviceInterface';



@Injectable({
  providedIn: 'root'
})
export class AccountService implements AccountServiceInterface {

    protected basePath = 'http://staging.convercus.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object") {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create a new account
     * Adds an account to the system
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAccount(interactionId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<string>;
    public addAccount(interactionId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<string>>;
    public addAccount(interactionId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<string>>;
    public addAccount(interactionId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addAccount.');
        }

        let headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<string>(`${this.configuration.basePath}/accounts`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new Identifier
     * Adds an Identifier for the account to the system
     * @param accountId ID of the account that will be fetched
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param body body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAccountIdentifier(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: IdentifierData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public addAccountIdentifier(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: IdentifierData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public addAccountIdentifier(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: IdentifierData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public addAccountIdentifier(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: IdentifierData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling addAccountIdentifier.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addAccountIdentifier.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/identifiers`,
            body,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new Booking
     * Adds an booking to the account
     * @param accountId ID of the account that will be fetched
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param body body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addBooking(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: BookingData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public addBooking(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: BookingData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public addBooking(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: BookingData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public addBooking(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: BookingData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling addBooking.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addBooking.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/bookings`,
            body,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create the Fraud and lock account
     * Adds an fraud to the account and set the account state to locked
     * @param accountId ID of the account that will be fetched
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param body body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addFraud(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: FraudData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public addFraud(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: FraudData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public addFraud(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: FraudData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public addFraud(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: FraudData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling addFraud.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addFraud.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/fraud`,
            body,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new LevelBooking
     * Adds an Levelbooking to the account
     * @param accountId ID of the account that will be fetched
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param body body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addLevelBooking(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: LevelBookingData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public addLevelBooking(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: LevelBookingData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public addLevelBooking(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: LevelBookingData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public addLevelBooking(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: LevelBookingData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling addLevelBooking.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addLevelBooking.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/level/bookings`,
            body,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new Membership
     * Adds an membership to the account
     * @param accountId ID of the account that will be fetched
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param body body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addMembership(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: MembershipData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public addMembership(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: MembershipData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public addMembership(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: MembershipData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public addMembership(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: MembershipData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling addMembership.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addMembership.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/memberships`,
            body,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new PushToken for the connected account and device
     * Adds an new PushTokn to the account
     * @param accountId ID of the account of the new PushToken
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param body body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addPushToken(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: PushTokenData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public addPushToken(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: PushTokenData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public addPushToken(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: PushTokenData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public addPushToken(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: PushTokenData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling addPushToken.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addPushToken.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/pushtoken`,
            body,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new Transaction
     * Adds a transaction to the account
     * @param accountId ID of the account that will receive the transaction
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param body body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addTransaction(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: TransactionData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AccountTransaction>;
    public addTransaction(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: TransactionData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AccountTransaction>>;
    public addTransaction(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: TransactionData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AccountTransaction>>;
    public addTransaction(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: TransactionData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling addTransaction.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addTransaction.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<AccountTransaction>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/transactions`,
            body,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a preview of a Transaction
     * Create a preview of a Transaction
     * @param accountId ID of the account that will receive the transaction
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param body body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTransactionPreview(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: TransactionData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AccountTransaction>;
    public createTransactionPreview(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: TransactionData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AccountTransaction>>;
    public createTransactionPreview(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: TransactionData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AccountTransaction>>;
    public createTransactionPreview(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: TransactionData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling createTransactionPreview.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling createTransactionPreview.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<AccountTransaction>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/transactions/preview`,
            body,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes an account
     * delete the account with the specified id
     * @param accountId ID of the account that should be deleted
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAccount(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public deleteAccount(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public deleteAccount(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public deleteAccount(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling deleteAccount.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling deleteAccount.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create the Fraud and unlock account
     * remove the fraud from the account and set the account state to active
     * @param accountId ID of the account that will be fetched
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFraud(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public deleteFraud(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public deleteFraud(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public deleteFraud(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling deleteFraud.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling deleteFraud.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/fraud`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the PushToken for the connected account and device
     * Deletes the PushToken for the specified account and device
     * @param accountId ID of the account of the PushToken
     * @param deviceId Unique ID of the device for which the PushTokens should be deleted
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePushToken(accountId: string, deviceId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public deletePushToken(accountId: string, deviceId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public deletePushToken(accountId: string, deviceId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public deletePushToken(accountId: string, deviceId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling deletePushToken.');
        }
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling deletePushToken.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling deletePushToken.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (deviceId !== undefined && deviceId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>deviceId, 'deviceId');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/pushtoken`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Balanceinformations for the account
     * returns the accountBalance for the the specified account
     * @param accountId ID of the account that will be fetched
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAccountBalance(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AccountBalance>;
    public getAccountBalance(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AccountBalance>>;
    public getAccountBalance(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AccountBalance>>;
    public getAccountBalance(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getAccountBalance.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getAccountBalance.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<AccountBalance>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/balance`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the specific account
     * returns an account specified by the given accountId
     * @param accountId ID of the account that will be fetched
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAccountById(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Account>;
    public getAccountById(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Account>>;
    public getAccountById(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Account>>;
    public getAccountById(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getAccountById.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getAccountById.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Account>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all account coupons for the specified user
     * search for all available coupons for the specified account
     * @param accountid ID of the account that will be fetched
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAccountCoupons(accountid: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<AccountCoupon>>;
    public getAccountCoupons(accountid: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<AccountCoupon>>>;
    public getAccountCoupons(accountid: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<AccountCoupon>>>;
    public getAccountCoupons(accountid: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountid === null || accountid === undefined) {
            throw new Error('Required parameter accountid was null or undefined when calling getAccountCoupons.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getAccountCoupons.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<AccountCoupon>>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountid))}/accountcoupons`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Balanceinformations for the account. Returned points are displayed as EUR
     * returns the accountBalance for the the specified account with points displayed as EUR
     * @param accountId ID of the account that will be fetched
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAccountCurrencyBalance(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CurrencyBalance>;
    public getAccountCurrencyBalance(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CurrencyBalance>>;
    public getAccountCurrencyBalance(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CurrencyBalance>>;
    public getAccountCurrencyBalance(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getAccountCurrencyBalance.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getAccountCurrencyBalance.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<CurrencyBalance>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/balance/EUR`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get identifiers for the account
     * returns the list of all identifiers for the the specified account
     * @param accountId ID of the account that will be fetched
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAccountIdentifiers(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Identifier>>;
    public getAccountIdentifiers(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Identifier>>>;
    public getAccountIdentifiers(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Identifier>>>;
    public getAccountIdentifiers(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getAccountIdentifiers.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getAccountIdentifiers.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<Identifier>>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/identifiers`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Statusinformations for the account
     * returns the accountlevel for the the specified account
     * @param accountId ID of the account that will be fetched
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAccountLevel(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<StateLevel>;
    public getAccountLevel(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<StateLevel>>;
    public getAccountLevel(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<StateLevel>>;
    public getAccountLevel(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getAccountLevel.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getAccountLevel.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<StateLevel>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/level`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get bookings for the account
     * returns the bookings for the the specified account
     * @param accountId ID of the account that will be fetched
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookings(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Booking>>;
    public getBookings(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Booking>>>;
    public getBookings(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Booking>>>;
    public getBookings(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getBookings.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getBookings.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<Booking>>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/bookings`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the fraudInfo for the account
     * returns the fraudInfo for the the specified account
     * @param accountId ID of the account that will be fetched
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFraudInfo(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Fraud>;
    public getFraudInfo(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Fraud>>;
    public getFraudInfo(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Fraud>>;
    public getFraudInfo(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getFraudInfo.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getFraudInfo.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Fraud>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/fraud`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get levelBookings for the account
     * returns the LevelBookings for the the specified account
     * @param accountId ID of the account that will be fetched
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLevelBookings(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<LevelBooking>>;
    public getLevelBookings(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<LevelBooking>>>;
    public getLevelBookings(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<LevelBooking>>>;
    public getLevelBookings(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getLevelBookings.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getLevelBookings.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<LevelBooking>>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/level/bookings`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get membership for the account
     * returns the membership for the the specified membership Id
     * @param accountId ID of the account that will be fetched
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param membershipId ID of the memebership that will be fetched
     * @param idType id-type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMembership(accountId: string, interactionId: string, membershipId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Membership>;
    public getMembership(accountId: string, interactionId: string, membershipId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Membership>>;
    public getMembership(accountId: string, interactionId: string, membershipId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Membership>>;
    public getMembership(accountId: string, interactionId: string, membershipId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getMembership.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getMembership.');
        }
        if (membershipId === null || membershipId === undefined) {
            throw new Error('Required parameter membershipId was null or undefined when calling getMembership.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Membership>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/memberships/${encodeURIComponent(String(membershipId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get memberships for the account
     * returns the memberships for the the specified account
     * @param accountId ID of the account that will be fetched
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMemberships(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Membership>>;
    public getMemberships(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Membership>>>;
    public getMemberships(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Membership>>>;
    public getMemberships(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getMemberships.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getMemberships.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<Membership>>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/memberships`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the PushTokens for the account
     * returns the PushTokens for the the specified account
     * @param accountId ID of the account that will be fetched
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPushTokens(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<PushToken>>;
    public getPushTokens(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<PushToken>>>;
    public getPushTokens(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<PushToken>>>;
    public getPushTokens(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getPushTokens.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getPushTokens.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<PushToken>>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/pushtoken`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get transactions for the account
     * returns all transactions for the specified account
     * @param accountId ID of the account that will receive the transaction
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param idType id-type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTransactions(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<AccountTransaction>>;
    public getTransactions(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<AccountTransaction>>>;
    public getTransactions(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<AccountTransaction>>>;
    public getTransactions(accountId: string, interactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getTransactions.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getTransactions.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<AccountTransaction>>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/transactions`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Incentivate an existing Transaction
     * Incentivate a transaction to the account
     * @param accountId ID of the account that will receive the transaction
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param transactionId ID of the original transaction that will be updated with accounting information
     * @param idType id-type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public incentivateTransaction(accountId: string, interactionId: string, transactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AccountTransaction>;
    public incentivateTransaction(accountId: string, interactionId: string, transactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AccountTransaction>>;
    public incentivateTransaction(accountId: string, interactionId: string, transactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AccountTransaction>>;
    public incentivateTransaction(accountId: string, interactionId: string, transactionId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling incentivateTransaction.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling incentivateTransaction.');
        }
        if (transactionId === null || transactionId === undefined) {
            throw new Error('Required parameter transactionId was null or undefined when calling incentivateTransaction.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<AccountTransaction>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/transactions/${encodeURIComponent(String(transactionId))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * merge an account into an other account
     * delete the account with the specified id
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param primaryAccountId ID of the master account
     * @param secondaryAccountId ID of the slave account
     * @param primaryIdType primary-id-type
     * @param secondaryIdType secondary-id-type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mergeAccount(interactionId: string, primaryAccountId: string, secondaryAccountId: string, primaryIdType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', secondaryIdType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public mergeAccount(interactionId: string, primaryAccountId: string, secondaryAccountId: string, primaryIdType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', secondaryIdType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public mergeAccount(interactionId: string, primaryAccountId: string, secondaryAccountId: string, primaryIdType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', secondaryIdType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public mergeAccount(interactionId: string, primaryAccountId: string, secondaryAccountId: string, primaryIdType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', secondaryIdType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling mergeAccount.');
        }
        if (primaryAccountId === null || primaryAccountId === undefined) {
            throw new Error('Required parameter primaryAccountId was null or undefined when calling mergeAccount.');
        }
        if (secondaryAccountId === null || secondaryAccountId === undefined) {
            throw new Error('Required parameter secondaryAccountId was null or undefined when calling mergeAccount.');
        }

        let headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        if (primaryIdType !== undefined && primaryIdType !== null) {
            headers = headers.set('primary-id-type', String(primaryIdType));
        }
        if (secondaryIdType !== undefined && secondaryIdType !== null) {
            headers = headers.set('secondary-id-type', String(secondaryIdType));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(primaryAccountId))}/merge/${encodeURIComponent(String(secondaryAccountId))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update Membership Optins
     * update the membership Optins
     * @param accountId ID of the account that will be fetched
     * @param interactionId unique id of an interaction point which can be mapped to one client
     * @param membershipId ID of the memebership that will be fetched
     * @param idType id-type
     * @param body body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMembershipOptins(accountId: string, interactionId: string, membershipId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: Array<Optin>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public updateMembershipOptins(accountId: string, interactionId: string, membershipId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: Array<Optin>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public updateMembershipOptins(accountId: string, interactionId: string, membershipId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: Array<Optin>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public updateMembershipOptins(accountId: string, interactionId: string, membershipId: string, idType?: 'ID' | 'APPCODE' | 'CARDCODE' | 'EXTERNALCODE', body?: Array<Optin>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling updateMembershipOptins.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling updateMembershipOptins.');
        }
        if (membershipId === null || membershipId === undefined) {
            throw new Error('Required parameter membershipId was null or undefined when calling updateMembershipOptins.');
        }

        let headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }

        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<any>(`${this.configuration.basePath}/accounts/${encodeURIComponent(String(accountId))}/memberships/${encodeURIComponent(String(membershipId))}`,
            body,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
