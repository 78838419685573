/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LinkedTransaction { 
    linkType: LinkedTransaction.LinkTypeEnum;
    /**
     * Link to an existing transaction, type of reference is defined by linkType.
     */
    linkValue: string;
}
export namespace LinkedTransaction {
    export type LinkTypeEnum = 'TRANSACTIONID' | 'EXTERNALID';
    export const LinkTypeEnum = {
        TRANSACTIONID: 'TRANSACTIONID' as LinkTypeEnum,
        EXTERNALID: 'EXTERNALID' as LinkTypeEnum
    };
}


