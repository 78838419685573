/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Account { 
    id?: string;
    program?: string;
    status?: Account.StatusEnum;
}
export namespace Account {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'LOCKED' | 'TERMINATED';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum,
        LOCKED: 'LOCKED' as StatusEnum,
        TERMINATED: 'TERMINATED' as StatusEnum
    };
}


