/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LineItem { 
    actualSalesUnitPrice?: number;
    /**
     * Currency code of the line item
     */
    currencyCode?: string;
    /**
     * Descriptiohn of the article
     */
    description?: string;
    extendedAmount?: number;
    /**
     * Articel code in the external system
     */
    itemID?: string;
    /**
     * Merchandise group code, used for blacklist
     */
    merchandiseGroupCode?: string;
    /**
     * Description of merchandise group code
     */
    merchandiseGroupName?: string;
    /**
     * Merchandise subgroup code, used for blacklist
     */
    merchandiseSubGroupCode?: string;
    /**
     * Description of merchandise subgroup code
     */
    merchandiseSubGroupName?: string;
    pointRelevant?: boolean;
    /**
     * Amount (of points) that has been booked by this transaction. For Earn transactions, this amount only includes basic points directly generated from revenue. This does not include any extra points.
     */
    points?: number;
    quantity?: number;
    /**
     * Sequence number of the item
     */
    sequenceNumber?: number;
    /**
     * Tax rate of the line item
     */
    taxRate?: number;
    /**
     * Return or sale item
     */
    type?: LineItem.TypeEnum;
}
export namespace LineItem {
    export type TypeEnum = 'SALE' | 'RETURN';
    export const TypeEnum = {
        SALE: 'SALE' as TypeEnum,
        RETURN: 'RETURN' as TypeEnum
    };
}


