import { Component, OnInit, Input } from "@angular/core";
import { TranslateService, ToastService } from "shared/lib/common/services";
import { ModalController, NavParams } from "@ionic/angular";

export interface ICheckPinModalPageOutput {
  data?: boolean;
}

export interface ICheckPinModalPageInput {
  pin: string;
}

@Component({
  selector: "shared-check-pin-modal",
  templateUrl: "./check-pin-modal.page.html",
  styleUrls: ["./check-pin-modal.page.scss"],
})
export class CheckPinModalPage implements OnInit {
  @Input() private params: ICheckPinModalPageInput;

  constructor(
    private navParams: NavParams,
    private modal: ModalController,
    public translate: TranslateService,
    private toast: ToastService,
  ) {}

  public ngOnInit(): void {
    this.params = this.navParams.data as ICheckPinModalPageInput;
  }

  public async checkPin(pin: string): Promise<void> {
    const isCorrectPin = pin === this.params.pin;
    this.dismiss(isCorrectPin);
    if (!isCorrectPin) this.toast.show(this.translate._("INVALID_PIN"), "error");
  }

  public dismiss(correctPin: boolean = false): void {
    this.modal.dismiss(correctPin);
  }
}
