import { Injectable, Inject, Optional } from "@angular/core";
import {
  TransactionService as ConvercusTransactionService,
  Configuration,
  BASE_PATH,
  TransactionData,
} from "shared/lib/v2/apis/transactions";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { UtilsService, ConfigService, EnvironmentService } from "shared/lib/common/services";

@Injectable({ providedIn: "root" })
export class TransactionService extends ConvercusTransactionService {
  constructor(
    private config: ConfigService,
    private utils: UtilsService,
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    protected environmentService: EnvironmentService,
  ) {
    super(httpClient, environmentService.getVariable("API_V2"), configuration);
  }

  public createTransaction(interactionId: string, body: TransactionData): Observable<any> {
    const store = this.config.getMountingConfig().store;
    return super.createTransaction(interactionId, {
      externalId: `${Number(body.amount).toFixed(2)}_${store ? store.vatId : store}_${this.utils.getReceiptFormatDate(new Date())}`,
      ...body,
    });
  }
}
