/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Optin } from './optin';
import { CustomProperty } from './custom-property';


export interface MembershipDetails { 
    birthDate?: string;
    city?: string;
    /**
     * Country Code (iso 3166-1 alpha-2)
     */
    countryCode?: string;
    customProperties?: Array<CustomProperty>;
    emailAddress?: string;
    familyName?: string;
    genderCode?: MembershipDetails.GenderCodeEnum;
    givenName?: string;
    memberRole?: MembershipDetails.MemberRoleEnum;
    optins?: Array<Optin>;
    streetHouseNo?: string;
    /**
     * user identifier
     */
    userId?: string;
    zipCode?: string;
}
export namespace MembershipDetails {
    export type GenderCodeEnum = 'MALE' | 'FEMALE' | 'DIVERSE';
    export const GenderCodeEnum = {
        MALE: 'MALE' as GenderCodeEnum,
        FEMALE: 'FEMALE' as GenderCodeEnum,
        DIVERSE: 'DIVERSE' as GenderCodeEnum
    };
    export type MemberRoleEnum = 'OWNER' | 'COOWNER' | 'COLLECTOR';
    export const MemberRoleEnum = {
        OWNER: 'OWNER' as MemberRoleEnum,
        COOWNER: 'COOWNER' as MemberRoleEnum,
        COLLECTOR: 'COLLECTOR' as MemberRoleEnum
    };
}


